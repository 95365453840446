import { INoNameContract } from '@amfi/connect-wallet/src/interface';
import { contracts, ContractsNames, mapChainToRpc } from 'services/WalletService/config';
import store from 'store/configureStore';
import { Chains, ChainType } from 'types';
import Web3 from 'web3';

export class ContractsService {
  private provider: Web3;

  constructor(provider?: Web3) {
    const { network, chainType } = store.store.getState().user;
    const rpc = mapChainToRpc[network][chainType];
    this.provider = provider || new Web3(rpc);
  }

  public static getContractData(contractName: ContractsNames, network: Chains, chainType: ChainType): INoNameContract {
    const { abi, address } = contracts[contractName][chainType];
    return {
      address: address[network],
      abi,
    };
  }
}
