import { ContractsService } from 'services';
import { ContractsNames } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import { updateUserState } from 'store/user/reducer';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { Chains } from '../../../types/connect/index';
import { getUserLockedBalance } from '../actions';
import actionTypes from '../actionTypes';

export function* getTokenBalanceSaga({ type, payload: { web3Provider } }: ReturnType<typeof getUserLockedBalance>) {
  try {
    yield put(request(type));
    const { address: userWalletAddress, chainType } = yield* select(userSelector.getUser);

    if (!userWalletAddress) return;
    const tokenContractData = ContractsService.getContractData(ContractsNames.transfer, Chains.Heco, chainType);
    const contract = new web3Provider.eth.Contract(tokenContractData.abi, tokenContractData.address);
    const userLockedBalance: string = yield call(contract.methods.balanceOfLocked(userWalletAddress).call);
    yield put(
      updateUserState({
        userLockedBalance,
      }),
    );

    yield put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_LOCKED_BALANCE, getTokenBalanceSaga);
}
