import {
  ComponentsOverrides,
  ComponentsProps,
  PaginationItem,
  paginationItemClasses,
  Theme,
  touchRippleClasses,
} from '@mui/material';
import { COLOR_DARKEST_MINT, COLOR_GRAY, COLOR_MINT_PRIMARY, COLOR_MINT_PRIMARY_ALPHA_15 } from 'theme/variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiPaginationOverrides = (theme: Theme): ComponentsOverrides['MuiPagination'] => ({
  ul: {
    '& li': {
      lineHeight: 0,
      [`& .${paginationItemClasses.root}`]: {
        height: 24,
        minWidth: 24,
        border: 'none',
        borderRadius: '50%',
        color: COLOR_GRAY,
      },
      [`& .${paginationItemClasses.previousNext}`]: {
        height: 36,
        width: 36,
        border: `1px solid ${COLOR_MINT_PRIMARY}`,
        borderRadius: theme.spacing(1),
        background: COLOR_DARKEST_MINT,
        [`&.${paginationItemClasses.disabled}`]: {
          opacity: 1,
          border: 'none',

          [`& .${paginationItemClasses.icon}`]: {
            fill: COLOR_GRAY,
          },
        },

        [`& .${paginationItemClasses.icon}`]: {
          fill: COLOR_MINT_PRIMARY,
        },
      },
      [`& .${paginationItemClasses.selected}`]: {
        color: COLOR_MINT_PRIMARY,
        background: `${COLOR_MINT_PRIMARY_ALPHA_15} !important`,
      },
      [`& .${touchRippleClasses.root}`]: {
        display: 'none',
      },
    },
  },
});

export const getMuiPaginationDefaultProps = (): ComponentsProps['MuiPagination'] => ({
  renderItem: (item) => <PaginationItem {...item} />,
});
