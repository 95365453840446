import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import userActionTypes from 'store/user/actionTypes';

import { modalsReducer } from './modals/reducer';

export const ignoredActions = [
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  modalsReducer.actions.setActiveModal({}).type,

  ...Object.values(userActionTypes),
];
