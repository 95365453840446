import userActionTypes from 'store/user/actionTypes';
import { UIState } from 'types';
import { RequestStatus } from 'types/store';

import { getUIReducer } from '.';

const getInitActionTypesRequestStatus = (actionTypesObj: any) => {
  return Object.keys(actionTypesObj).reduce((acc, actionType) => {
    return {
      ...acc,
      [actionType]: RequestStatus.INIT,
    };
  }, {});
};

const initialState: UIState = {
  ...getInitActionTypesRequestStatus(userActionTypes),
};

const uiReducer = getUIReducer(initialState);

export default uiReducer;
