export enum Modals {
  ConnectWallet = 'ConnectWallet',
  DisconnectWallet = 'DisconnectWallet',

  ApprovePending = 'ApprovePending',
  ApproveRejected = 'ApproveRejected',
  SendPending = 'SendPending',
  SendRejected = 'SendRejected',
  SendSuccess = 'SendSuccess',
  TransferSuccess = 'TransferSuccess',

  init = '',
}

export interface ModalState {
  activeModal: Modals;
  txHash: string;
  open: boolean;
  repeatCallback?: () => void;
}

export type ModalsInitialState = {
  modalState: ModalState;
};
