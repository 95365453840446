export const notifyText = {
  disconnect: {
    info: 'Disconnect from wallet',
  },
  approve: {
    success: 'Successful approve',
    error: 'Error approve',
  },

  createRefLink: {
    success: 'Successfully created new referral link',
    error: (message?: string) => `Unable to create referral link${message ? `: ${message}` : ''}`,
  },
  deactivateRefLink: {
    success: 'Successfully deactivated referral link',
    error: 'Unable to deactivate referral link',
  },

  updateBonusInfo: {
    success: 'Your bonus info was updated',
    notFound: 'This code doesn`t exist',
    disabledLink: `Can't fetch referral bonus of disabled referral code`,
    sameUser: `You cannot make a purchase using your own referral code`,
  },

  buyToken: {
    success: 'Successfully bought tokens',
    error: 'Unable to buy tokens',
    disabledLink: 'Provided referral code is disabled',
  },
};

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const NATIVE_CURRENCY_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const ZERO_BYTES_32 = '0x0000000000000000000000000000000000000000000000000000000000000000';
