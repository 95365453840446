import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IConnect, IError } from '@amfi/connect-wallet/dist/interface';
import { useShallowSelector } from 'hooks';
import { Subscription } from 'rxjs';
import { chains } from 'services/WalletService/config';
import { notifyText } from 'services/WalletService/config/constants';
import { getUserLockedBalance } from 'store/user/actions';
import { disconnectWalletState, updateUserState } from 'store/user/reducer';
import userSelector from 'store/user/selectors';
import { Chains, WalletProviders } from 'types';
import { shortenPhrase, showToastMessage } from 'utils';

import { WalletService } from '../WalletService';

interface IContextValue {
  connect: (provider: WalletProviders, chain: Chains) => Promise<void>;
  disconnect: () => void;
  walletService: WalletService;
}

type IAccountInfo = IConnect | IError | { address: string };

const Web3Context = createContext({} as IContextValue);

const WalletConnectContext: FC<PropsWithChildren> = ({ children }) => {
  const subscriberRef = useRef<Subscription | null>(null);

  const WalletConnect = useMemo(() => new WalletService(), []);
  const dispatch = useDispatch();
  const { provider: WalletProvider, chainType, network } = useShallowSelector(userSelector.getUser);

  const disconnect = useCallback(() => {
    dispatch(disconnectWalletState());
    WalletConnect.resetConnect();
    subscriberRef.current?.unsubscribe();
    subscriberRef.current = null;
    showToastMessage('info', notifyText.disconnect.info);
  }, [WalletConnect, dispatch]);

  const subscriberSuccess = useCallback(
    (res: { name: string; address: string }) => {
      if (document.visibilityState !== 'visible') {
        disconnect();
        return;
      }

      if (res.name === 'accountsChanged') {
        dispatch(updateUserState({ address: res.address }));
      }
    },
    [disconnect, dispatch],
  );

  const subscriberError = useCallback(
    (error: { code: number }) => {
      // eslint-disable-next-line no-console
      console.error(error);
      if (error.code === 4) {
        showToastMessage('error', `You changed to wrong network. Please choose ${network} ${chainType}`);
        disconnect();
      }
    },
    [chainType, disconnect, network],
  );

  const connect = useCallback(
    async (provider: WalletProviders, chain: Chains) => {
      const connected = await WalletConnect.initWalletConnect(provider, chain, chainType);
      if (connected) {
        try {
          subscriberRef.current = WalletConnect.eventSubscribe().subscribe(subscriberSuccess, subscriberError);
          const accountInfo: IAccountInfo = await WalletConnect.getAccount();
          const accountAddress = (accountInfo as IConnect).address;
          if (accountAddress) {
            dispatch(
              updateUserState({
                provider: (accountInfo as IError).type,
                address: accountAddress,
                network: chain,
              }),
            );
            dispatch(getUserLockedBalance({ web3Provider: WalletConnect.Web3() }));
            showToastMessage('success', `Wallet connected: ${shortenPhrase(accountAddress, 3, 3)}`);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          if (!window.ethereum) {
            // redirect to download MM or open MM on mobile
            const { host, pathname } = window.location;
            // pathname can be just '/' or '/somethings/index.ts'
            // need to cut last '/'
            const pathnameWithoutLastSlash = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
            window.open(`https://metamask.app.link/dapp/${host}${pathnameWithoutLastSlash}/?utm_source=mm`);
            return;
          }

          if (error.code === 4 && error.type === 'MetaMask') {
            // Add unknown chain for MetaMask
            const { network: networkConfig } = chains[chain][chainType];
            if (networkConfig) {
              // @see https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
              window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [networkConfig],
              });
            }
          }
        }
      }
    },
    [WalletConnect, chainType, dispatch, subscriberError, subscriberSuccess],
  );

  useEffect(() => {
    // connect user if he connected previously
    if (WalletProvider && network) {
      connect(WalletProviders.metamask, network);
    }
    // @disable-reason: this effect should work only once on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Web3Context.Provider value={{ connect, disconnect, walletService: WalletConnect }}>
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);

export { WalletConnectContext, useWalletConnectorContext };
