import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_LIGHT_MINT_SECONDARY, COLOR_MINT_PRIMARY, COLOR_MINT_PRIMARY_ALPHA_10 } from 'theme/variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiSliderOverrides = (theme: Theme): ComponentsOverrides['MuiSlider'] => ({
  root: {
    color: COLOR_MINT_PRIMARY,
  },
  track: {
    border: 'none',
    boxShadow: `0px 29.168px 38.8907px ${COLOR_MINT_PRIMARY_ALPHA_10}`,
  },
  rail: {
    backgroundColor: '#666666',
    boxShadow: `0px 29.168px 38.8907px ${COLOR_MINT_PRIMARY_ALPHA_10}`,
  },
  thumb: {
    height: 32,
    width: 32,
    backgroundColor: 'currentColor',
    border: `5px solid ${COLOR_LIGHT_MINT_SECONDARY}`,
    boxShadow: '0px 3.88907px 1.94454px rgba(0, 0, 0, 0.5)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '0px 3.88907px 1.94454px rgba(0, 0, 0, 0.5)',
    },
    '&:before': {
      display: 'none',
    },
  },
});

export const getMuiSliderDefaultProps = (): ComponentsProps['MuiSlider'] => ({
  'aria-label': 'slider',
  step: 1,
});
