import { Token } from 'types';

import { ContractsNames } from './contracts';

export type Tokens = ContractsNames.transfer;

export const tokens: Record<Tokens, Token> = {
  [ContractsNames.transfer]: {
    address: '',
    symbol: 'BTCMT',
    decimals: 18,
  },
};
