import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BG_MAIN, COLOR_MINT_PRIMARY } from 'theme/variables';

import { FontFamilies } from '../variables/typography';

import '@fontsource/manrope/400.css';
import '@fontsource/manrope/500.css';
import '@fontsource/manrope/600.css';
import '@fontsource/manrope/700.css';
import '@fontsource/manrope/800.css';

export const getMuiCssBaselineOverrides = (theme: Theme): ComponentsOverrides['MuiCssBaseline'] => ({
  '*::-webkit-scrollbar': {
    width: 6,
  },
  '*::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: COLOR_MINT_PRIMARY,
  },
  html: {
    WebkitFontSmoothing: 'antialiased', // Antialiasing.
    MozOsxFontSmoothing: 'grayscale', // Antialiasing.
    boxSizing: 'border-box',
  },
  body: {
    overflowX: 'hidden',
    overflowY: 'overlay',
    margin: theme.spacing(0),
    fontFamily: FontFamilies.primary,
    fontSize: 14,
    lineHeight: '24px',
    background: BG_MAIN,

    'span, div': {
      fontFeatureSettings: 'liga off',
    },
  },
});

export const getMuiCssBaselineDefaultProps = (): ComponentsProps['MuiCssBaseline'] => ({});
