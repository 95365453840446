import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FooterDecoration: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 106 145"
    sx={{
      width: 106,
      height: 145,
      fill: 'none',
      ...sx,
    }}
  >
    <path
      d="M47.9514 12.9485L30.1533 3.2944C26.9173 1.56853 23.0341 1.56853 19.7981 3.2944L2 12.9485"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.9514 25.3528L30.1533 15.6987C26.9173 13.9728 23.0341 13.9728 19.7981 15.6987L2 25.3528"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.9514 37.7581L30.1533 28.104C26.9173 26.3781 23.0341 26.3781 19.7981 28.104L2 37.7581"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.9514 50.1624L30.1533 40.5083C26.9173 38.7824 23.0341 38.7824 19.7981 40.5083L2 50.1624"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.0915 132.214L75.8896 141.868C79.1256 143.594 83.0089 143.594 86.2449 141.868L104.043 132.214"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.0915 119.809L75.8896 129.463C79.1256 131.189 83.0089 131.189 86.2449 129.463L104.043 119.809"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.0915 107.404L75.8896 117.058C79.1256 118.784 83.0089 118.784 86.2449 117.058L104.043 107.404"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.0915 94.9997L75.8896 104.654C79.1256 106.38 83.0089 106.38 86.2449 104.654L104.043 94.9997"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.9514 72.2757L30.1533 62.6215C26.9173 60.8957 23.0341 60.8957 19.7981 62.6216L2 72.2757"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.9514 84.68L30.1533 75.0258C26.9173 73.3 23.0341 73.3 19.7981 75.0258L2 84.68"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.9514 97.0852L30.1533 87.4311C26.9173 85.7052 23.0341 85.7052 19.7981 87.4311L2 97.0852"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.9514 109.49L30.1533 99.8354C26.9173 98.1095 23.0341 98.1095 19.7981 99.8354L2 109.49"
      stroke="currentColor"
      strokeWidth="2.69668"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
