import { SvgIconProps } from '@mui/material';
import { COLOR_DARK_MINT } from 'theme/variables';

import { SpinnerPositionProps, SpinnerSizeProps } from './Spinner.types';

const sizeState: Record<SpinnerSizeProps, SvgIconProps['sx']> = {
  s: {
    width: '100px',
    height: '100px',
  },
  xl: {
    width: '600px',
  },
};

const positionState: Record<SpinnerPositionProps, SvgIconProps['sx']> = {
  center: {
    position: 'fixed',
    top: '50%',
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'translate(-50%, -50%)',
  },
  initial: {
    position: 'inherit',
  },
};

const colorState: SvgIconProps['sx'] = {
  color: COLOR_DARK_MINT,
};

export const spinnerStyleState = {
  size: sizeState,
  position: positionState,
  color: colorState,
};
