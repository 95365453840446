/* PLOP_INJECT_IMPORT_REDUCER */
import modals from './modals/reducer';
import ui from './ui/reducer';
import user from './user/reducer';

export default {
  ui,
  user,
  modals,
  /* PLOP_INJECT_PLACE_REDUCER */
};
