import { COLOR_DARK_MINT, COLOR_DARKEST_MINT, COLOR_GRAY, COLOR_MINT_PRIMARY, COLOR_WHITE } from './colors';
import { BORDER_RADIUS_EXTRA_LARGE } from './common';

// ===== Backgrounds
export const BG_BUTTON_PRIMARY = COLOR_MINT_PRIMARY;
export const BG_BUTTON_PRIMARY_HOVER = COLOR_DARK_MINT;
export const BG_BUTTON_PRIMARY_DISABLED = COLOR_GRAY;

export const BG_BUTTON_SECONDARY = COLOR_WHITE;
export const BG_BUTTON_SECONDARY_HOVER = COLOR_DARK_MINT;
export const BG_BUTTON_SECONDARY_DISABLED = COLOR_DARKEST_MINT;

// ===== Typography
export const COLOR_BUTTON_PRIMARY = COLOR_DARKEST_MINT;
export const COLOR_BUTTON_PRIMARY_HOVER = COLOR_MINT_PRIMARY;
export const COLOR_BUTTON_PRIMARY_DISABLED = COLOR_DARKEST_MINT;

export const COLOR_BUTTON_SECONDARY = COLOR_DARK_MINT;
export const COLOR_BUTTON_SECONDARY_HOVER = COLOR_WHITE;
export const COLOR_BUTTON_SECONDARY_DISABLED = COLOR_GRAY;

// ===== Borders
export const BORDER_BUTTON_PRIMARY = `2px solid ${COLOR_MINT_PRIMARY}`;
export const BORDER_BUTTON_PRIMARY_HOVER = `2px solid ${COLOR_DARK_MINT}`;
export const BORDER_BUTTON_PRIMARY_DISABLED = `2px solid ${COLOR_GRAY}`;

export const BORDER_BUTTON_SECONDARY = `2px solid ${COLOR_DARK_MINT}`;
export const BORDER_BUTTON_SECONDARY_HOVER = `2px solid ${COLOR_DARK_MINT}`;
export const BORDER_BUTTON_SECONDARY_DISABLED = `2px solid ${COLOR_GRAY}`;

// ===== Border-Radius
export const BORDER_RADIUS_BUTTON_DEFAULT = BORDER_RADIUS_EXTRA_LARGE;
