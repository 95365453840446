import { Icon } from 'components';

export const socialsItems = [
  {
    id: 1,
    Icon: Icon.Twitter,
    link: 'https://twitter.com/btcmtofficial',
  },
  {
    id: 2,
    Icon: Icon.Discord,
    link: 'https://discord.com/invite/minto',
  },
  {
    id: 3,
    Icon: Icon.Telegram,
    link: 'https://t.me/btcmtofficial',
  },
  {
    id: 4,
    Icon: Icon.Mail,
    link: 'mailto:info@minto.finance',
  },
];
