import { BoxProps, svgIconClasses, SxProps, Theme } from '@mui/material';
import {
  COLOR_BLACK,
  COLOR_DARK_MINT,
  COLOR_DARKEST_MINT,
  COLOR_LIGHT_MINT,
  COLOR_MINT_PRIMARY,
  COLOR_TEXT_WHITE,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';

export type Color = 'primary' | 'secondary';
export type Variant = 'input' | 'icon';

export const boxesState: Record<Color, BoxProps['sx']> = {
  primary: {
    background: COLOR_BLACK,
    color: COLOR_TEXT_WHITE,
    border: '2px solid',
    borderColor: COLOR_BLACK,
    transition: TRANSITION_DEFAULT_TIME,
    [`& .${svgIconClasses.root}`]: {
      color: COLOR_MINT_PRIMARY,
    },
    '&:hover': {
      borderColor: COLOR_MINT_PRIMARY,
    },
  },
  secondary: {
    background: COLOR_LIGHT_MINT,
    color: COLOR_DARKEST_MINT,
    border: '2px solid',
    borderColor: COLOR_LIGHT_MINT,
    transition: TRANSITION_DEFAULT_TIME,
    '&:hover': {
      color: COLOR_DARK_MINT,
      borderColor: COLOR_DARK_MINT,
    },
  },
};
export const colorsState: Record<Color, SxProps<Theme>> = {
  primary: {},
  secondary: {},
};

export const COPY_TEXT_SHOW_TIME = 1500;

export const copyText = {
  success: 'Copied!',
  error: 'Not copied!',
};
