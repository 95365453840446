import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, BoxProps, Button, Grid, Tooltip, Typography } from '@mui/material';
import { Copy } from 'components/Icon/components';
import { BORDER_RADIUS_DEFAULT } from 'theme/variables';

import {
  boxesState,
  Color,
  colorsState,
  COPY_TEXT_SHOW_TIME,
  copyText as copyTextHelpers,
  Variant,
} from './CopyText.helpers';

export type CopyTextProps = {
  variant?: Variant;
  color?: Color;
  copyText: string;
} & PropsWithChildren &
  Pick<BoxProps, 'sx'>;

export const CopyText: FC<CopyTextProps> = ({ variant = 'input', color = 'primary', copyText, children, sx }) => {
  const [helperText, setHelperText] = useState('');
  const [isCopiedTooltipVisible, setIsCopiedTooltipVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopiedTooltipVisible(false);
    }, COPY_TEXT_SHOW_TIME);
    return () => clearTimeout(timer);
  }, [isCopiedTooltipVisible]);

  const handleCopyAddress = () => {
    setHelperText(copyTextHelpers.success);
    setIsCopiedTooltipVisible(true);
  };

  return (
    <>
      {variant === 'input' && (
        <CopyToClipboard text={copyText}>
          <Box
            px={2}
            sx={
              {
                width: '100%',
                borderRadius: BORDER_RADIUS_DEFAULT,
                ...boxesState[color],
                ...sx,
              } as BoxProps['sx']
            }
          >
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              xs={12}
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                variant="caption"
                noWrap
                sx={{
                  color: 'inherit',
                  ...colorsState[color],
                }}
              >
                {children}
              </Typography>
              <Tooltip
                title={helperText}
                arrow
                followCursor={false}
                open={isCopiedTooltipVisible}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <Button
                  variant="text"
                  color="inherit"
                  startIcon={
                    <Copy
                      sx={{
                        color: 'inherit',
                        ...colorsState[color],
                      }}
                    />
                  }
                  sx={{
                    p: 0,
                    paddingLeft: 1,
                  }}
                  onClick={handleCopyAddress}
                />
              </Tooltip>
            </Grid>
          </Box>
        </CopyToClipboard>
      )}
      {variant === 'icon' && (
        <CopyToClipboard text={copyText}>
          <Box sx={sx}>
            <Tooltip
              title={helperText}
              arrow
              followCursor={false}
              open={isCopiedTooltipVisible}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button
                variant="text"
                color="inherit"
                startIcon={
                  <Copy
                    sx={{
                      color: 'inherit',
                      ...colorsState[color],
                    }}
                  />
                }
                sx={{
                  p: 0,
                }}
                onClick={handleCopyAddress}
              />
            </Tooltip>
          </Box>
        </CopyToClipboard>
      )}
    </>
  );
};
