import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { COLOR_BLACK, COLOR_GRAY_5 } from 'theme/variables';

export type ConfirmDeactivateRefLinkModalProps = {
  onDeactivateRefLink: () => void;
} & ModalProps;

export const ConfirmDeactivateRefLinkModal: FC<ConfirmDeactivateRefLinkModalProps> = ({
  onDeactivateRefLink,
  open,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose} closable={false}>
      <Stack spacing={2}>
        <Typography variant="h5" align="center" color={COLOR_BLACK}>
          Are you sure you want to deactivate this link?
        </Typography>
        <Typography variant="body1" align="center" color={COLOR_GRAY_5}>
          New referrals won`t be able to join it. Your current referrals joined by this link will stay.
        </Typography>

        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={1}
        >
          <Button size="large" variant="contained" fullWidth onClick={onDeactivateRefLink}>
            Yes
          </Button>
          <Button size="large" variant="outlined" fullWidth onClick={onClose}>
            No
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
