import { COLOR_BLACK, COLOR_MINT_PRIMARY_ALPHA_15 } from './colors';

// ===== Backgrounds
export const BG_MAIN = COLOR_BLACK;
export const BG_BLUE = 'rgba(94, 153, 245, 1)';
export const BG_BLUE_ACCENT = 'rgba(10, 59, 135, 1)';
export const BG_BLUE_LIGHT = 'rgba(231, 240, 254, 1)';
export const BG_BLUE_EXTRALIGHT = 'rgba(237, 237, 237, 1)';
export const BG_WHITE_OPACITY = 'rgba(255, 255, 255, 0.15)';
export const BG_BLUE_DARK = 'rgba(10, 59, 135, 1)';
export const BG_MODAL_GRAY = 'rgba(76, 76, 76, 0.6)';
export const BG_GRAY_LIGHT = '#E7F1FE';
export const BG_GRAY = 'rgba(237, 237, 237, 1)';

// ===== Typography
export const COLOR_TEXT_BLACK = '#000000';
export const COLOR_TEXT_WHITE = '#FFFFFF';
export const COLOR_TEXT_WHITE_LIGHT = 'rgba(250, 250, 252, 1)';
export const COLOR_TEXT_WHITE_EXTRALIGHT = 'rgba(255, 255, 255, 0.7)';
export const COLOR_TEXT_BLUE = 'rgba(94, 153, 245, 1)';
export const COLOR_TEXT_ACCENT_BLUE = 'rgba(10, 59, 135, 1)';
export const COLOR_TEXT_GRAY = 'rgba(181, 181, 181, 1)';
export const COLOR_TEXT_GRAY_DARK = 'rgba(76, 76, 76, 1)';
export const COLOR_TEXT_GRAY_LIGHT = 'rgba(112, 112, 112, 1)';
export const COLOR_TEXT_GRAY_EXTRALIGHT = 'rgba(216, 216, 216, 1)';
export const COLOR_TEXT_RED = 'rgba(226, 71, 37, 1)';
export const COLOR_TEXT_GREEN = 'rgba(26, 192, 87, 1)';
export const COLOR_LIGHT_BLUE = 'rgb(231, 240, 254, 1)';

// ===== Borders
export const BORDER_HEADER = '2px solid rgba(237, 237, 237, 1)';
export const BORDER_GRAY_LIGHT = '1px solid rgba(0, 0, 0, 0.08)';

// ===== Border-Radius
export const BORDER_RADIUS_DEFAULT = '8px';
export const BORDER_RADIUS_MEDIUM = '12px';
export const BORDER_RADIUS_POPOVER = '12px';
export const BORDER_RADIUS_MODAL_DEFAULT = '16px';
export const BORDER_RADIUS_LARGE = '24px';
export const BORDER_RADIUS_EXTRA_LARGE = '32px';

// ===== Box-Shadow
export const SHADOW_DEFAULT = `0px 2px 30px ${COLOR_MINT_PRIMARY_ALPHA_15}`;
export const SHADOW_MODAL_DEFAULT = SHADOW_DEFAULT;

// ===== Transitions
export const TRANSITION_DEFAULT_TIME = 'all 0.3s ease-out';
