import { FC, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Container, Stack } from '@mui/material';
import { useSmoothTopScroll } from 'hooks';
import { theme } from 'theme';
import { COLOR_MINT_PRIMARY, TRANSITION_DEFAULT_TIME } from 'theme/variables';

import { BodyDecoration } from '../../assets/img';
import { AppModal } from '../AppModal';
import { Footer, Header } from '..';

export interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation();

  const firstPathAtPathname = useMemo(() => pathname.split('/')[1], [pathname]);
  useSmoothTopScroll(firstPathAtPathname);

  return (
    <Stack sx={{ height: '100vh' }}>
      <Header />
      <BodyDecoration
        sx={{
          position: 'fixed',
          zIndex: -1,
          top: 153,
          left: -95,
          [theme.breakpoints.up(1340)]: {
            left: -25,
          },
          transition: TRANSITION_DEFAULT_TIME,
          color: COLOR_MINT_PRIMARY,
        }}
      />
      <BodyDecoration
        sx={{
          position: 'fixed',
          zIndex: -1,
          top: 403,
          right: -95,
          [theme.breakpoints.up(1340)]: {
            right: -25,
          },
          transition: TRANSITION_DEFAULT_TIME,
          color: COLOR_MINT_PRIMARY,
        }}
      />
      <Container
        sx={{
          px: 3,
          maxWidth: 1128,
          position: 'relative',
        }}
        component="main"
      >
        {children}
      </Container>
      <Footer />
      <AppModal />
    </Stack>
  );
};
