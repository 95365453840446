import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import { BORDER_RADIUS_DEFAULT } from 'theme/variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiTooltipOverrides = (theme: Theme): ComponentsOverrides['MuiTooltip'] => ({
  tooltip: {
    borderRadius: BORDER_RADIUS_DEFAULT,
    background: '#272727',
    border: '1px solid #5F5F5F',
    fontWeight: FontWeights.Bold,
    fontSize: '12px',
    lineHeight: '16px',
    padding: theme.spacing(1, 1.5),
    whiteSpace: 'pre',
    color: '#DFDFDF',
  },
});

export const getMuiTooltipDefaultProps = (): ComponentsProps['MuiTooltip'] => ({
  arrow: true,
  placement: 'top',
  leaveDelay: 300,
  followCursor: true,
});
