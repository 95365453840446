import { Transfer } from 'modules/transfer/pages';
import { Modules, RoutesProps } from 'types';

/**
@Description
key 'dirName' - should be equal to component dir name
@example MyInvestments - name of dir and dirName: MyInvestments
*/

export const routes: RoutesProps = {
  transfer: {
    dirName: 'Transfer',
    root: {
      id: 1,
      title: '',
      path: '/',
      component: <Transfer />,
      isNavItem: true,
      module: Modules.transfer,
    },
  },
};
