import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Stack, Typography } from '@mui/material';
import { CopyText, Modal } from 'components';
import { useShallowSelector } from 'hooks';
import { setActiveModal } from 'store/modals/reducer';
import modalsSelector from 'store/modals/selectors';
import useSelector from 'store/user/selectors';
import { FontWeights } from 'theme/Typography';
import { COLOR_DARK_MINT, COLOR_DARKEST_MINT } from 'theme/variables';
import { Modals } from 'types/store';
import { constructExplorerUrl } from 'utils';

import { modalData } from './TransactionModal.helpers';

const modalTheme = {
  primary: {
    title1: COLOR_DARKEST_MINT,
    title2: COLOR_DARK_MINT,
    subtitle: COLOR_DARKEST_MINT,
    icon: COLOR_DARK_MINT,
    body1: COLOR_DARKEST_MINT,
    body2: '#8A90AB',
    isOkButton: false,
  },
};

type TransactionModalProps = {
  variant?: 'primary';
};

export const TransactionModal: FC<TransactionModalProps> = ({ variant = 'primary' }) => {
  const dispatch = useDispatch();
  const { network, chainType } = useShallowSelector(useSelector.getUser);
  const { modalState } = useShallowSelector(modalsSelector.getModals);
  const { title1, title2, Icon, body1, body2, isCopyable, repeatTransactions, isOkButton } =
    // disable-reason: due to Partial<Modals> TS expects that there possible undefined, but IT CANNOT BE
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    modalData[modalState.activeModal]!;

  const explorerLink = constructExplorerUrl(modalState.txHash, network, chainType, 'tx');

  const closeModal = useCallback(() => {
    dispatch(
      setActiveModal({
        activeModal: Modals.init,
        txHash: '',
        open: false,
      }),
    );
  }, [dispatch]);

  const ModalTitle = (
    <Stack direction="row" justifyContent="center" spacing={1}>
      <Typography variant="h5" lineHeight="24px" color={modalTheme[variant].title1}>
        {title1}
      </Typography>
      <Typography variant="h5" lineHeight="24px" color={modalTheme[variant].title2}>
        {title2}
      </Typography>
    </Stack>
  );

  return (
    <Modal
      open={modalState.open}
      onClose={closeModal}
      title={ModalTitle}
      sx={{
        width: 'auto',
        border: '1px solid #00FFC2',
      }}
    >
      <Stack
        alignItems="center"
        spacing={3}
        sx={{
          minWidth: { xs: '100%', sm: '100%', md: 550 },
          paddingTop: 3,
        }}
      >
        {Icon && (
          <Box
            sx={{
              color: modalTheme[variant].icon,
            }}
          >
            <Icon />
          </Box>
        )}
        {body1 && (
          <Typography
            fontSize="22px"
            lineHeight="28px"
            fontWeight={FontWeights.ExtraBold}
            color={modalTheme[variant].body1}
            textAlign="center"
          >
            {body1}
          </Typography>
        )}
        {body2 && (
          <Typography
            fontSize="14px"
            lineHeight="22px"
            fontWeight={FontWeights.Medium}
            color={modalTheme[variant].body2}
            textAlign="center"
            maxWidth={537}
          >
            {body2}
          </Typography>
        )}
        {isCopyable && (
          <CopyText sx={{ height: 48, maxWidth: 384 }} copyText={explorerLink} color="secondary">
            {explorerLink}
          </CopyText>
        )}
        {repeatTransactions &&
          (modalState.activeModal === Modals.ApproveRejected || modalState.activeModal === Modals.SendRejected) && (
            <Button
              sx={{
                minWidth: 237,
              }}
              onClick={modalState.repeatCallback}
            >
              {repeatTransactions} again
            </Button>
          )}
        {isOkButton && (
          <Button sx={{ px: 6, py: 2.5 }} onClick={closeModal}>
            Ok
          </Button>
        )}
      </Stack>
    </Modal>
  );
};
