import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Wallet: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: 24,
      height: 24,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0101 10.1709L21.0127 13.7938C20.9872 14.1389 20.9543 14.4837 20.9141 14.8278C20.8468 15.4032 20.379 15.8673 19.7916 15.933C17.9519 16.1386 16.0481 16.1386 14.2083 15.933C13.6209 15.8673 13.1531 15.4032 13.0858 14.8278C12.8661 12.9488 12.8661 11.0505 13.0858 9.17153C13.1531 8.59612 13.6209 8.13198 14.2083 8.06632C16.0481 7.8607 17.9519 7.8607 19.7916 8.06632C20.379 8.13198 20.8468 8.59612 20.9141 9.17153C20.953 9.50413 20.985 9.83734 21.0101 10.1709ZM17 10.4996C16.1715 10.4996 15.5 11.1712 15.5 11.9996C15.5 12.8281 16.1715 13.4996 17 13.4996C17.8284 13.4996 18.5 12.8281 18.5 11.9996C18.5 11.1712 17.8284 10.4996 17 10.4996Z"
      fill="currentColor"
    />
    <path
      d="M20.4036 6.03936C20.559 6.30863 20.2672 6.61014 19.9582 6.57561C18.0077 6.35761 15.9922 6.35761 14.0417 6.57561C12.7723 6.71747 11.7457 7.71701 11.596 8.99727C11.3627 10.9921 11.3627 13.0072 11.596 15.002C11.7457 16.2823 12.7723 17.2818 14.0417 17.4237C15.9922 17.6417 18.0077 17.6417 19.9582 17.4237C20.2693 17.3889 20.5635 17.6925 20.4065 17.9633C19.6279 19.3061 18.2398 20.2433 16.6268 20.4131L15.9749 20.4817C12.6641 20.8301 9.32462 20.8071 6.01887 20.4132L5.58692 20.3617C3.8048 20.1493 2.39275 18.757 2.15538 16.978C1.71448 13.6737 1.71448 10.3256 2.15538 7.0213C2.39275 5.24233 3.8048 3.84998 5.58692 3.63761L6.01886 3.58614C9.32461 3.19221 12.6641 3.16924 15.9749 3.51764L16.6268 3.58624C18.2412 3.75612 19.6271 4.69473 20.4036 6.03936Z"
      fill="currentColor"
    />
  </SvgIcon>
);
