import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import {
  BG_BUTTON_PRIMARY,
  BORDER_RADIUS_MODAL_DEFAULT,
  COLOR_DARK_MINT,
  COLOR_TEXT_WHITE,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';

export const getMuiToggleButtonOverrides = (theme: Theme): ComponentsOverrides['MuiToggleButton'] => ({
  root: {
    border: 'none',
    borderRadius: BORDER_RADIUS_MODAL_DEFAULT,
    padding: theme.spacing(2, 5),

    '&.MuiToggleButton-root': {
      background: BG_BUTTON_PRIMARY,
      transition: TRANSITION_DEFAULT_TIME,
      '&.Mui-selected': {
        background: COLOR_DARK_MINT,
        color: COLOR_TEXT_WHITE,
      },
    },
  },
});

export const getMuiToggleButtonDefaultProps = (): ComponentsProps['MuiToggleButton'] => ({});
