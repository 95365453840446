import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainLogoText: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 85 24"
    sx={{
      width: 85,
      height: 24,
      ...sx,
    }}
  >
    <path
      d="M0.0390625 22.7728C0.0390625 23.1166 0.318463 23.3953 0.663132 23.3953H3.37757C3.72217 23.3953 4.00157 23.1166 4.00157 22.7728V11.3636C4.00157 11.0333 4.44941 10.9304 4.59416 11.2275L10.3518 23.045C10.4562 23.2593 10.6741 23.3953 10.913 23.3953H11.6296C11.8684 23.3953 12.0864 23.2593 12.1908 23.045L17.9486 11.2275C18.0934 10.9304 18.5409 11.0333 18.5409 11.3636V22.7728C18.5409 23.1166 18.8203 23.3953 19.165 23.3953H21.8797C22.2244 23.3953 22.5038 23.1166 22.5038 22.7728V1.60714C22.5038 1.26334 22.2244 0.984619 21.8797 0.984619H19.0831C18.847 0.984619 18.6305 1.11826 18.5251 1.32968L11.5506 15.3033C11.4358 15.5333 11.1068 15.5333 10.992 15.3033L4.01776 1.32968C3.91225 1.11826 3.69589 0.984619 3.45921 0.984619H0.663132C0.318463 0.984619 0.0390625 1.26334 0.0390625 1.60714V22.7728Z"
      fill="currentColor"
    />
    <path
      d="M27.1949 3.62847C27.1949 3.97228 27.4743 4.25099 27.819 4.25099H30.8137C31.1584 4.25099 31.4378 3.97228 31.4378 3.62847V1.13839C31.4378 0.794579 31.1584 0.515869 30.8137 0.515869H27.819C27.4743 0.515869 27.1949 0.794579 27.1949 1.13839V3.62847ZM27.1949 22.771C27.1949 23.1148 27.4743 23.3935 27.819 23.3935H30.8137C31.1584 23.3935 31.4378 23.1148 31.4378 22.771V7.20797C31.4378 6.86413 31.1584 6.58542 30.8137 6.58542H27.819C27.4743 6.58542 27.1949 6.86413 27.1949 7.20797V22.771Z"
      fill="currentColor"
    />
    <path
      d="M36.0808 22.772C36.0808 23.1157 36.3602 23.3944 36.7049 23.3944H39.7624C40.1071 23.3944 40.3865 23.1157 40.3865 22.772V14.8659C40.3865 13.818 40.5007 12.9776 40.7299 12.3447C40.9686 11.7118 41.2702 11.2346 41.6346 10.9129C41.9984 10.5809 42.3888 10.3579 42.8047 10.2437C43.2205 10.1296 43.6103 10.0725 43.9747 10.0725C44.8794 10.0725 45.5765 10.2748 46.0647 10.6795C46.5643 11.0841 46.923 11.5873 47.1414 12.1891C47.3598 12.7909 47.49 13.3926 47.5312 13.9944C47.5731 14.5858 47.5941 15.0734 47.5941 15.4573V22.772C47.5941 23.1157 47.8734 23.3944 48.2175 23.3944H51.2757C51.6204 23.3944 51.8998 23.1157 51.8998 22.772V13.9632C51.8998 13.6831 51.8788 13.237 51.8369 12.6249C51.7956 12.0127 51.6756 11.3383 51.4782 10.6017C51.2808 9.85462 50.9481 9.13876 50.4796 8.454C50.0225 7.76918 49.3774 7.20373 48.5451 6.75759C47.7134 6.31145 46.6367 6.08838 45.3162 6.08838C44.0992 6.08838 43.0383 6.29074 42.1336 6.69538C41.4956 6.98085 40.9293 7.33355 40.436 7.75337C40.2189 7.93807 39.8564 7.7925 39.8564 7.50787V7.20895C39.8564 6.86511 39.577 6.5864 39.2323 6.5864H36.7049C36.3602 6.5864 36.0808 6.86511 36.0808 7.20895V22.772Z"
      fill="currentColor"
    />
    <path
      d="M65.548 23.4833C65.8445 23.4369 66.0585 23.1786 66.0585 22.879V20.5909C66.0585 20.2139 65.7252 19.9247 65.3487 19.9599C64.6243 20.0279 63.9513 20.0423 63.3285 20.0029C62.5064 19.9511 61.909 19.6294 61.5344 19.038C61.337 18.7267 61.2278 18.3117 61.2068 17.7929C61.196 17.2638 61.1909 16.6724 61.1909 16.0188V10.4784C61.1909 10.1345 61.4703 9.85581 61.815 9.85581H65.4344C65.7791 9.85581 66.0585 9.5771 66.0585 9.23332V7.21014C66.0585 6.86629 65.7791 6.58758 65.4344 6.58758H61.815C61.4703 6.58758 61.1909 6.30887 61.1909 5.96509V2.54122C61.1909 2.19741 60.9116 1.9187 60.5669 1.9187H57.5716C57.2275 1.9187 56.9475 2.19741 56.9475 2.54122V5.96509C56.9475 6.30887 56.6681 6.58758 56.3241 6.58758H54.7013C54.3566 6.58758 54.0773 6.86629 54.0773 7.21014V9.23332C54.0773 9.5771 54.3566 9.85581 54.7013 9.85581H56.3241C56.6681 9.85581 56.9475 10.1345 56.9475 10.4784V16.2678C56.9475 17.2742 56.9583 18.1769 56.9792 18.9757C56.9996 19.7643 57.2179 20.5424 57.6344 21.3102C58.0915 22.1299 58.7575 22.7264 59.6311 23.1C60.5148 23.4631 61.5134 23.655 62.6264 23.6758C63.585 23.7023 64.5589 23.6382 65.548 23.4833Z"
      fill="currentColor"
    />
    <path
      d="M76.451 23.8627C78.1359 23.8627 79.6177 23.4891 80.8969 22.7421C82.187 21.9847 83.1907 20.942 83.9081 19.614C84.6363 18.2755 85.0001 16.7348 85.0001 14.9918C85.0001 13.2591 84.6414 11.7287 83.924 10.4007C83.2059 9.06225 82.2022 8.01433 80.9128 7.25691C79.6335 6.49956 78.146 6.12085 76.451 6.12085C74.787 6.12085 73.3154 6.49433 72.0361 7.24136C70.7568 7.98839 69.7531 9.03114 69.0256 10.3696C68.2974 11.6976 67.9336 13.2383 67.9336 14.9918C67.9336 16.7244 68.2872 18.26 68.9945 19.5984C69.7119 20.9264 70.7105 21.9692 71.9891 22.7265C73.2684 23.484 74.7559 23.8627 76.451 23.8627ZM76.451 19.9097C75.1304 19.9097 74.1267 19.4687 73.4404 18.5868C72.7643 17.6945 72.4265 16.4961 72.4265 14.9918C72.4265 13.5288 72.7484 12.346 73.3934 11.4434C74.0486 10.5304 75.0676 10.0738 76.451 10.0738C77.7924 10.0738 78.8019 10.52 79.4774 11.4123C80.1643 12.3045 80.5071 13.4977 80.5071 14.9918C80.5071 16.4339 80.1694 17.6167 79.4932 18.5401C78.8273 19.4532 77.8134 19.9097 76.451 19.9097Z"
      fill="currentColor"
    />
  </SvgIcon>
);
