import { FC } from 'react';
import { styled, SvgIconProps } from '@mui/material';

import { Icon } from '../Icon';

import { SpinnerPositionProps, SpinnerSizeProps } from './Spinner.types';
import { spinnerStyleState } from './styleState';

export interface SpinnerProps {
  size?: SpinnerSizeProps;
  position?: SpinnerPositionProps;
  type?: 'default' | 'simple';
}

const Loading = styled(Icon.Loading)({
  animation: 'rotate 1s linear infinite',
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(1turn)',
    },
  },
});

export const Spinner: FC<SpinnerProps> = ({ size = 's', position = 'initial', type = 'default' }) => {
  if (type === 'default') {
    return (
      <Loading
        sx={
          {
            ...spinnerStyleState.size[size],
            ...spinnerStyleState.position[position],
            ...spinnerStyleState.color,
          } as SvgIconProps['sx']
        }
      />
    );
  }
  return <Loading />;
};
