import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box, Button, Container, Grid, IconButton, Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { CopyText, Icon } from 'components';
import { useModal, useShallowSelector } from 'hooks';
import { formatRoutesToArr } from 'modules/router/utils';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { theme } from 'theme';
import { FontWeights } from 'theme/Typography';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  COLOR_BLACK,
  COLOR_DARK_MINT,
  COLOR_GRAY_4,
  COLOR_MINT_PRIMARY,
  COLOR_MINT_PRIMARY_ALPHA_15,
  COLOR_TEXT_WHITE,
  COLOR_WHITE,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';
import { Modals } from 'types';

import { MobileMenu } from './components';

const StyledNavLink = styled(NavLink)({
  textDecoration: 'none',
});

export const Header: FC = () => {
  const dispatch = useDispatch();

  const address = useShallowSelector(userSelector.getProp('address'));
  const isWalletConnected = Boolean(address);

  const [isMenuOpen, onOpenMenu, onCloseMenu] = useModal(false);

  const handleOpenModal = useCallback(
    (type: Modals) => {
      dispatch(
        setActiveModal({
          activeModal: type,
          txHash: '',
          open: true,
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          top: '0',
          left: '0',
          zIndex: '2',
          width: '100%',
          transition: TRANSITION_DEFAULT_TIME,
        }}
        component="header"
      >
        <Container
          sx={{
            pt: {
              xs: 1.5,
              sm: 4,
            },
            px: 3,
            maxWidth: 1128,
          }}
        >
          <Stack
            spacing={{
              xs: 1.5,
              md: 0,
            }}
            sx={{
              position: 'relative',
            }}
          >
            <Stack
              direction={{ xs: 'row' }}
              alignItems="center"
              sx={{
                padding: {
                  xs: theme.spacing(1.25, 3),
                  md: theme.spacing(2, 3),
                },
                background: COLOR_GRAY_4,
                borderRadius: BORDER_RADIUS_EXTRA_LARGE,
              }}
            >
              <Icon.MainLogo
                sx={{
                  color: COLOR_MINT_PRIMARY,
                }}
              />
              <Icon.MainLogoText
                sx={{
                  ml: 1.5,
                  mr: 12.5,
                  color: COLOR_WHITE,
                }}
              />

              <Stack
                display={{
                  xs: 'none',
                  md: 'flex',
                }}
                direction="row"
                spacing={4}
              >
                {formatRoutesToArr(routes).map(
                  ({ root: { id, path, title, isNavItem, isProtected } }) =>
                    isNavItem &&
                    (isProtected === isWalletConnected || !isProtected) && (
                      <StyledNavLink key={id} to={path}>
                        {({ isActive }) => (
                          <Typography
                            letterSpacing="0.02em"
                            sx={{
                              color: isActive ? COLOR_MINT_PRIMARY : COLOR_TEXT_WHITE,
                              fontWeight: FontWeights.Bold,
                              fontSize: '14px',
                              lineHeight: '24px',
                              textDecoration: 'none',
                              textTransform: 'uppercase',
                              transition: TRANSITION_DEFAULT_TIME,
                              '&:hover': {
                                color: COLOR_MINT_PRIMARY_ALPHA_15,
                              },
                            }}
                          >
                            {title}
                          </Typography>
                        )}
                      </StyledNavLink>
                    ),
                )}
              </Stack>

              <IconButton
                sx={{
                  display: {
                    xs: 'flex',
                    md: 'none',
                  },
                  position: 'absolute',
                  right: 24,
                  color: COLOR_WHITE,
                }}
                onClick={onOpenMenu}
              >
                <MenuRoundedIcon />
              </IconButton>
            </Stack>

            <Box
              sx={{
                position: {
                  xs: 'relative',
                  md: 'absolute',
                },
                right: {
                  xs: 'initial',
                  md: 24,
                },
                top: {
                  xs: 'initial',
                  md: '50%',
                },
                transform: {
                  xs: 'initial',
                  md: 'translateY(-50%)',
                },
              }}
            >
              {isWalletConnected ? (
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="nowrap"
                  xs={12}
                  sx={{
                    height: {
                      xs: 56,
                      md: 40,
                    },

                    maxWidth: {
                      xs: 'initial',
                      md: 230,
                    },
                    borderRadius: '30px',
                    borderColor: {
                      xs: COLOR_MINT_PRIMARY,
                      md: COLOR_BLACK,
                    },

                    background: COLOR_BLACK,
                    border: '2px solid',
                    transition: TRANSITION_DEFAULT_TIME,
                    '&:hover': {
                      borderColor: COLOR_MINT_PRIMARY,
                    },
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      position: 'relative',
                      width: '100%',
                      height: 'inherit',
                      justifyContent: 'initial',
                      p: 0,
                    }}
                    onClick={() => handleOpenModal(Modals.DisconnectWallet)}
                  >
                    <IconButton
                      sx={{
                        position: 'absolute',
                        left: {
                          xs: 8,
                          md: -3,
                        },
                        width: 40,
                        height: 40,
                        padding: 0.75,
                        background: COLOR_DARK_MINT,

                        color: COLOR_WHITE,
                        '&:hover': {
                          background: COLOR_DARK_MINT,
                        },
                      }}
                    >
                      <AccountBalanceWalletRoundedIcon />
                    </IconButton>

                    <Box
                      sx={{
                        pl: {
                          xs: 6.5,
                          md: 5.75,
                        },
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        color: COLOR_MINT_PRIMARY,
                      }}
                    >
                      <Typography variant="caption" noWrap color={COLOR_MINT_PRIMARY}>
                        {address}
                      </Typography>
                    </Box>
                  </Button>
                  <CopyText
                    variant="icon"
                    copyText={address}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pl: 1,
                      pr: {
                        xs: 1,
                        md: 0.5,
                      },
                      color: COLOR_MINT_PRIMARY,
                    }}
                  >
                    {address}
                  </CopyText>
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => handleOpenModal(Modals.ConnectWallet)}
                  sx={{
                    height: {
                      xs: 54,
                      md: 36,
                    },
                    minWidth: 210,
                    width: {
                      xs: '100%',
                      md: 'initial',
                    },
                    fontSize: 16,
                  }}
                >
                  Connect wallet
                </Button>
              )}
            </Box>
          </Stack>
        </Container>
      </Box>

      {isMenuOpen && <MobileMenu open={isMenuOpen} onClose={onCloseMenu} isWalletConnected={isWalletConnected} />}
    </>
  );
};
