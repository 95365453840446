import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainLogo: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 47 32"
    sx={{
      width: 47,
      height: 32,
      ...sx,
    }}
  >
    <path
      d="M1.6861 0.914181C0.937227 0.481696 0 1.02079 0 1.88403V8.88247C0 9.6823 0.427221 10.4215 1.12106 10.8222L19.2761 21.307C21.597 22.6473 24.4591 22.6473 26.78 21.307L44.935 10.8222C45.6288 10.4215 46.0561 9.6823 46.0561 8.88247V1.88403C46.0561 1.02079 45.1189 0.481694 44.37 0.914181L25.6543 11.7227C24.0298 12.6609 22.0263 12.6609 20.4017 11.7227L1.6861 0.914181Z"
      fill="currentColor"
    />
    <path
      d="M1.68873 14.3679C0.939863 13.9327 0 14.4716 0 15.3362V22.0717C0 22.8694 0.424933 23.6069 1.11581 24.0084L13.2888 31.0824C14.0377 31.5176 14.9776 30.9786 14.9776 30.1141V23.3785C14.9776 22.5809 14.5526 21.8433 13.8618 21.4418L1.68873 14.3679Z"
      fill="currentColor"
    />
    <path
      d="M44.365 14.3679C45.1139 13.9327 46.0538 14.4716 46.0538 15.3362V22.0717C46.0538 22.8694 45.6288 23.6069 44.938 24.0084L32.7649 31.0824C32.0161 31.5176 31.0762 30.9786 31.0762 30.1141V23.3785C31.0762 22.5809 31.5011 21.8433 32.192 21.4418L44.365 14.3679Z"
      fill="currentColor"
    />
  </SvgIcon>
);
