import type {} from '@mui/lab/themeAugmentation'; // needs to import like this to support LoadingButton
import { createTheme, ThemeOptions } from '@mui/material';
import { getMuiButtonDefaultProps, getMuiButtonOverrides } from 'theme/Button';
import { getMuiContainerDefaultProps, getMuiContainerOverrides } from 'theme/Container';
import { breakpointOptions, getMuiGridDefaultProps, getMuiGridOverrides } from 'theme/Grid';
import { getMuiIconButtonDefaultProps, getMuiIconButtonOverrides } from 'theme/IconButton';
import { getMuiLoadingButtonDefaultProps, getMuiLoadingButtonOverrides } from 'theme/LoadingButton';
import { getMuiToggleButtonDefaultProps, getMuiToggleButtonOverrides } from 'theme/ToggleButton';
import { getTypographyOptions } from 'theme/Typography';
import { COLOR_TEXT_BLACK } from 'theme/variables';

import { getMuiAccordion, getMuiAccordionDetails, getMuiAccordionPropsDefaultProps } from './Accordion';
import { getMuiBackdropDefaultProps, getMuiBackdropOverrides } from './Backdrop';
import { getMuiBreadcrumbsDefaultProps, getMuiBreadcrumbsOverrides } from './Breadcrumbs';
import { getMuiCssBaselineDefaultProps, getMuiCssBaselineOverrides } from './CssBaseLine';
import { getMuiDialogDefaultProps, getMuiDialogOverrides } from './Dialog';
import { getMuiLinkDefaultProps, getMuiLinkOverrides } from './Link';
import { getMuiPaginationDefaultProps, getMuiPaginationOverrides } from './Pagination';
import { getMuiPopoverDefaultProps, getMuiPopoverOverrides } from './Popover';
import { getMuiSelectDefaultProps, getMuiSelectOverrides } from './Select';
import { getMuiSliderDefaultProps, getMuiSliderOverrides } from './Slider';
import { getMuiInputBaseOverrides, getMuiTextFieldDefaultProps, getMuiTextFieldOverrides } from './TextField';
import { getMuiTooltipDefaultProps, getMuiTooltipOverrides } from './Tooltip';

const themeBase = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: COLOR_TEXT_BLACK,
    },
  },
  typography: getTypographyOptions(),
  breakpoints: breakpointOptions,
});

export const theme = createTheme(themeBase, {
  components: {
    MuiGrid: {
      defaultProps: getMuiGridDefaultProps(),
      styleOverrides: getMuiGridOverrides(),
    },
    MuiContainer: {
      defaultProps: getMuiContainerDefaultProps(),
      styleOverrides: getMuiContainerOverrides(),
    },
    MuiBackdrop: {
      defaultProps: getMuiBackdropDefaultProps(),
      styleOverrides: getMuiBackdropOverrides(themeBase),
    },
    MuiButton: {
      defaultProps: getMuiButtonDefaultProps(),
      styleOverrides: getMuiButtonOverrides(themeBase),
    },
    MuiIconButton: {
      defaultProps: getMuiIconButtonDefaultProps(),
      styleOverrides: getMuiIconButtonOverrides(),
    },
    MuiToggleButton: {
      defaultProps: getMuiToggleButtonDefaultProps(),
      styleOverrides: getMuiToggleButtonOverrides(themeBase),
    },
    MuiLoadingButton: {
      defaultProps: getMuiLoadingButtonDefaultProps(),
      styleOverrides: getMuiLoadingButtonOverrides(),
    },
    MuiDialog: {
      defaultProps: getMuiDialogDefaultProps(),
      styleOverrides: getMuiDialogOverrides(themeBase),
    },
    MuiCssBaseline: {
      defaultProps: getMuiCssBaselineDefaultProps(),
      styleOverrides: getMuiCssBaselineOverrides(themeBase),
    },
    MuiPopover: {
      defaultProps: getMuiPopoverDefaultProps(),
      styleOverrides: getMuiPopoverOverrides(themeBase),
    },
    MuiBreadcrumbs: {
      defaultProps: getMuiBreadcrumbsDefaultProps(),
      styleOverrides: getMuiBreadcrumbsOverrides(),
    },
    MuiSelect: {
      defaultProps: getMuiSelectDefaultProps(),
      styleOverrides: getMuiSelectOverrides(themeBase),
    },
    MuiInputBase: {
      styleOverrides: getMuiInputBaseOverrides(),
    },
    MuiTextField: {
      defaultProps: getMuiTextFieldDefaultProps(themeBase),
      styleOverrides: getMuiTextFieldOverrides(themeBase),
    },
    MuiLink: {
      defaultProps: getMuiLinkDefaultProps(),
      styleOverrides: getMuiLinkOverrides(themeBase),
    },
    MuiAccordion: {
      defaultProps: getMuiAccordionPropsDefaultProps(),
      styleOverrides: getMuiAccordion(themeBase),
    },
    MuiAccordionDetails: {
      styleOverrides: getMuiAccordionDetails(themeBase),
    },
    MuiSlider: {
      defaultProps: getMuiSliderDefaultProps(),
      styleOverrides: getMuiSliderOverrides(themeBase),
    },
    MuiTooltip: {
      defaultProps: getMuiTooltipDefaultProps(),
      styleOverrides: getMuiTooltipOverrides(themeBase),
    },
    MuiPagination: {
      defaultProps: getMuiPaginationDefaultProps(),
      styleOverrides: getMuiPaginationOverrides(themeBase),
    },
  },
} as ThemeOptions);
