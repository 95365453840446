import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container, Divider, Stack, styled, TextField, Typography } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { SectionWithTitle } from 'modules/transfer/components';
import { useWalletConnectorContext } from 'services';
import { tokens } from 'services/WalletService/config';
import uiSelector from 'store/ui/selectors';
import { getUserLockedBalance, transferTokens } from 'store/user/actions';
import modalsSelector from 'store/user/selectors';
import { theme } from 'theme';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  BORDER_RADIUS_LARGE,
  COLOR_DARK_MINT,
  COLOR_DARK_MINT_ALPHA_20,
  COLOR_MINT_PRIMARY,
  COLOR_TEXT_WHITE,
} from 'theme/variables';
import { RequestStatus, State, UserState } from 'types';
import { fromDecimals, toDecimals, validateOnlyNumbers } from 'utils';

const tokenToTransfer = tokens.transfer;

const StyledDivider = styled(Divider)({
  opacity: 0.1,
  border: '1px solid #FFFFFF',
  margin: '24px 25px 0 25px !important',
});
export const Transfer: FC = () => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();
  const { userLockedBalance } = useShallowSelector<State, UserState>(modalsSelector.getUser);
  const { TRANSFER_TOKENS } = useShallowSelector(uiSelector.getUI);
  const isTransferInProgress = TRANSFER_TOKENS === RequestStatus.REQUEST;
  const [tokenAmount, setTokenAmount] = useState('');
  const [transferAddress, setTransferAddress] = useState('');
  const [isBuyDisabled, setIsBuyDisabled] = useState(true);
  useEffect(() => {
    dispatch(
      getUserLockedBalance({
        web3Provider: walletService.Web3(),
      }),
    );
  }, [dispatch, walletService]);
  useEffect(() => {
    if (
      tokenAmount &&
      toDecimals(tokenAmount, tokens.transfer.decimals) <= userLockedBalance &&
      walletService.Web3().utils.isAddress(transferAddress)
    )
      return setIsBuyDisabled(false);
    return setIsBuyDisabled(true);
  }, [tokenAmount, transferAddress, userLockedBalance, walletService]);
  const handleTransferClick = () => {
    dispatch(
      transferTokens({
        web3Provider: walletService.Web3(),
        amount: toDecimals(tokenAmount, tokens.transfer.decimals),
        addressTo: transferAddress,
      }),
    );
  };
  return (
    <Container
      sx={{
        width: '100%',
        maxWidth: 650,
        paddingTop: '56px',
        paddingBottom: '114px',
      }}
    >
      <Stack alignItems="center" spacing={2} mb={7}>
        <Typography variant="h1" align="center" color={COLOR_TEXT_WHITE}>
          Transfer your locked tokens
        </Typography>
      </Stack>
      <Stack alignItems="center" spacing={4}>
        <Stack
          sx={{
            width: '100%',
            maxWidth: 650,
            background: '#273333',
            borderRadius: BORDER_RADIUS_LARGE,
          }}
        >
          <Stack
            padding={{
              xs: theme.spacing(3, 2),
              sm: 3,
            }}
            spacing={3}
          >
            <SectionWithTitle title="Amount">
              <TextField
                name="amount"
                placeholder="Enter amount"
                value={tokenAmount}
                onChange={({ target }) => {
                  const newValue = target.value;
                  if (validateOnlyNumbers(newValue, tokenToTransfer.decimals)) {
                    setTokenAmount(newValue);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <Typography variant="body1" color={COLOR_MINT_PRIMARY}>
                      {tokenToTransfer.symbol}
                    </Typography>
                  ),
                }}
                sx={{
                  '& > .MuiOutlinedInput-root': {
                    color: COLOR_DARK_MINT,

                    '& input': {
                      color: COLOR_TEXT_WHITE,
                    },
                    '& input::placeholder': {
                      color: COLOR_DARK_MINT,
                    },
                  },
                }}
              />
              <Typography variant="caption" color="GrayText" align="right">
                Your locked BTCMT balance: {fromDecimals(userLockedBalance, tokenToTransfer.decimals, 2)}
              </Typography>
            </SectionWithTitle>
            <SectionWithTitle title="Address for transfer">
              <TextField
                name="address"
                placeholder="Enter address"
                value={transferAddress}
                onChange={({ target }) => {
                  const newValue = target.value;
                  setTransferAddress(newValue);
                }}
                sx={{
                  '& > .MuiOutlinedInput-root': {
                    color: COLOR_DARK_MINT,

                    '& input': {
                      color: COLOR_TEXT_WHITE,
                    },
                    '& input::placeholder': {
                      color: COLOR_DARK_MINT,
                    },
                  },
                }}
              />
            </SectionWithTitle>
            <StyledDivider />
            <Button
              size="large"
              type="submit"
              disabled={isBuyDisabled || isTransferInProgress}
              onClick={handleTransferClick}
              sx={{
                borderRadius: BORDER_RADIUS_EXTRA_LARGE,
                background: COLOR_MINT_PRIMARY,
                '&.Mui-disabled': {
                  border: 'none',
                  background: COLOR_DARK_MINT_ALPHA_20,
                },
              }}
            >
              <Typography fontWeight="bolder">Transfer</Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};
