import BigNumber from 'bignumber.js/bignumber';

import { formatNumber } from './numberFormatter';

/**
 * 1 -> 1000000000000000000
 */
export const toDecimals = (balance: string | number, decimals: string | number, shouldFormatNumber = false): string => {
  if (balance === '') {
    return '0';
  }

  if (typeof balance === 'number') {
    balance.toString();
  }

  const displayValue = new BigNumber(balance).multipliedBy(new BigNumber(10).pow(decimals));

  if (shouldFormatNumber) {
    const formattedValue = formatNumber(+displayValue.toString());
    return formattedValue;
  }

  return displayValue.toFixed();
};

/**
 * 1000000000000000000 -> 1
 */
export const fromDecimals = (
  balance: string | number | null | undefined,
  decimals: string | number,
  displayDecimals?: number | string,
): string => {
  if (!balance) {
    return '0';
  }

  if (typeof balance === 'number') {
    balance.toString();
  }

  const displayValue = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals));

  if (displayDecimals === undefined) {
    return displayValue.toFixed();
  }
  return displayValue.toFixed(+displayDecimals);
};
