import { FC, PropsWithChildren } from 'react';
import { Stack, styled, SxProps, Theme, Typography } from '@mui/material';
import { COLOR_TEXT_WHITE } from 'theme/variables';

type SectionWithTitleProps = PropsWithChildren<{
  title: string;
  sx?: SxProps<Theme>;
}>;

const SectionTitle = styled(Typography)({
  variant: 'body1',
  color: COLOR_TEXT_WHITE,
});
export const SectionWithTitle: FC<SectionWithTitleProps> = ({ title, children, sx }) => {
  return (
    <Stack spacing={1} sx={sx}>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </Stack>
  );
};
