import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Trash: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: 24,
      height: 24,
    }}
  >
    <path
      d="M19 7.5L18.1327 19.6425C18.0579 20.6891 17.187 21.5 16.1378 21.5H7.86224C6.81296 21.5 5.94208 20.6891 5.86732 19.6425L5 7.5M10 11.5V17.5M14 11.5V17.5M15 7.5V4.5C15 3.94772 14.5523 3.5 14 3.5H10C9.44772 3.5 9 3.94772 9 4.5V7.5M4 7.5H20"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
