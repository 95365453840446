import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Mail: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      ...sx,
      width: '16px',
      height: '16px',
    }}
  >
    <path
      d="M4.9019 3.27199C6.99165 3.09033 9.09324 3.09033 11.183 3.27199L12.1898 3.35951C12.8601 3.41777 13.4341 3.81755 13.7315 4.3894C13.7696 4.46256 13.7411 4.55113 13.671 4.59454L9.49365 7.18049C8.59774 7.7351 7.46806 7.74674 6.56092 7.2107L2.3557 4.7258C2.28776 4.68565 2.25658 4.60301 2.28719 4.53027C2.5593 3.8839 3.17105 3.42245 3.89507 3.35951L4.9019 3.27199Z"
      fill="currentColor"
    />
    <path
      d="M2.28377 5.84483C2.1798 5.78339 2.04713 5.84951 2.03501 5.96967C1.86585 7.64676 1.90679 9.33973 2.15783 11.0091C2.29017 11.8892 3.00844 12.5639 3.89507 12.641L4.9019 12.7285C6.99165 12.9102 9.09324 12.9102 11.183 12.7285L12.1898 12.641C13.0764 12.5639 13.7947 11.8892 13.9271 11.0091C14.1854 9.29108 14.2213 7.54807 14.0346 5.8231C14.0215 5.70208 13.8865 5.63718 13.7831 5.70125L10.02 8.03075C8.80789 8.78111 7.2795 8.79685 6.05219 8.07162L2.28377 5.84483Z"
      fill="currentColor"
    />
  </SvgIcon>
);
