import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { URL } from 'appConstants';
import { CopyText, Modal, ModalProps } from 'components';
import { COLOR_DARKEST_MINT } from 'theme/variables';

export type RefLinkGenerationModalProps = {
  referralCode?: string;
} & ModalProps;

export const RefLinkGenerationModal: FC<RefLinkGenerationModalProps> = ({
  referralCode = '',
  open,
  onClose,
  title = 'Referral link',
}) => {
  return (
    <Modal
      open={open}
      title={
        <Typography variant="h4" color={COLOR_DARKEST_MINT}>
          {title}
        </Typography>
      }
      closable={false}
      onClose={onClose}
    >
      <Stack pt={3} spacing={3}>
        <Stack spacing={1}>
          <Typography variant="caption" fontSize={18} color={COLOR_DARKEST_MINT}>
            Link
          </Typography>
          <CopyText
            copyText={URL.referrals.getRefLink(referralCode, true)}
            color="secondary"
            sx={{
              background: '#E9E9E9',
              borderColor: '#E9E9E9',
            }}
          >
            {URL.referrals.getRefLink(referralCode)}
          </CopyText>
        </Stack>

        <Stack spacing={1}>
          <Typography variant="caption" fontSize={18} color={COLOR_DARKEST_MINT}>
            Referral code
          </Typography>
          <CopyText
            copyText={referralCode}
            color="secondary"
            sx={{
              maxWidth: '50%',
              background: '#E9E9E9',
              borderColor: '#E9E9E9',
            }}
          >
            {referralCode}
          </CopyText>
        </Stack>

        <Button size="large" variant="contained" fullWidth onClick={onClose}>
          Accept
        </Button>
      </Stack>
    </Modal>
  );
};
