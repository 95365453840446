import { Chains, ChainType } from 'types';

export const mapChainToChainId: Record<
  Chains,
  {
    [chainType in ChainType]: number;
  }
> = {
  [Chains.Heco]: {
    mainnet: 128,
    testnet: 256,
  },
};

export const mapChainToRpc: Record<
  Chains,
  {
    [chainType in ChainType]: string;
  }
> = {
  [Chains.Heco]: {
    mainnet: 'https://http-mainnet.hecochain.com/',
    testnet: 'https://http-testnet.hecochain.com/',
  },
};

export const mapChainToBlockExplorerUrl: Record<
  Chains,
  {
    [chainType in ChainType]: string;
  }
> = {
  [Chains.Heco]: {
    mainnet: 'https://www.hecoinfo.com/en-us/',
    testnet: 'https://testnet.hecoinfo.com/en-us/',
  },
};
