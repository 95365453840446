const referrals = {
  links: 'links/',
  getRefLink: (referralLink: string, shouldPrefixWithProtocol = false) =>
    `${shouldPrefixWithProtocol ? window.location.origin : window.location.host}/buy?ref=${referralLink}`,
  participants: 'participants/',
  orders: 'orders/', // for token purchase via coingate
};

export default {
  referrals,
  mintoStaking: 'https://minto.finance/staking',
};
