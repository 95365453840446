import { ContractsService } from 'services';
import { ContractsNames } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, TransferAbi } from 'types';

import { Chains } from '../../../types/connect/index';
import { getUserLockedBalance, transferTokens } from '../actions';
import actionTypes from '../actionTypes';

export function* transferTokensSaga({
  type,
  payload: { web3Provider, amount, addressTo },
}: ReturnType<typeof transferTokens>) {
  try {
    yield put(request(type));
    const { address: userAddress, chainType } = yield select(userSelector.getUser);

    if (!userAddress) return;
    const tokenContractData = ContractsService.getContractData(ContractsNames.transfer, Chains.Heco, chainType);
    const contract: TransferAbi = yield new web3Provider.eth.Contract(tokenContractData.abi, tokenContractData.address);
    yield call(contract.methods.transferLocked(addressTo, amount).send, {
      from: userAddress,
    });
    yield put(
      setActiveModal({
        open: true,
        activeModal: Modals.TransferSuccess,
      }),
    );
    yield put(getUserLockedBalance({ web3Provider }));
    yield put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.TRANSFER_TOKENS, transferTokensSaga);
}
