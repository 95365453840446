import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Backdrop, Box, Divider, Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { formatRoutesToArr } from 'modules/router/utils';
import { FontWeights } from 'theme/Typography';
import {
  BORDER_RADIUS_MODAL_DEFAULT,
  COLOR_DARKEST_MINT,
  COLOR_MINT_PRIMARY,
  COLOR_MINT_PRIMARY_ALPHA_15,
  COLOR_TEXT_WHITE,
  COLOR_WHITE_ALPHA_5,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';

export type MenuProps = {
  open: boolean;
  onClose: () => void;
  isWalletConnected: boolean;
};

const StyledNavLink = styled(NavLink)({
  textDecoration: 'none',
});

export const MobileMenu: FC<MenuProps> = ({ open, onClose, isWalletConnected }) => {
  return (
    <Box
      sx={{
        display: { xs: 'block', md: 'none' },
      }}
    >
      <Backdrop open={open} onClick={onClose} sx={{ zIndex: 2 }} />
      <Box
        sx={{
          position: 'fixed',
          top: {
            xs: 72,
            sm: 92,
          },
          left: '50%',
          right: 0,
          zIndex: 3,
          overflowY: 'hidden',
          minWidth: 280,
          width: 'calc(100% - 2 * 16px)',
          transform: 'translateX(-50%)',
          background: COLOR_DARKEST_MINT,
          borderRadius: BORDER_RADIUS_MODAL_DEFAULT,
        }}
      >
        <Box
          sx={{
            p: 2,
            textAlign: 'center',
          }}
        >
          <Typography
            variant="caption"
            textTransform="uppercase"
            fontWeight={FontWeights.Bold}
            color={COLOR_MINT_PRIMARY}
          >
            Menu
          </Typography>
        </Box>

        <Divider
          sx={{
            width: '100%',
            height: '1px',
            background: `1px solid ${COLOR_WHITE_ALPHA_5}`,
          }}
        />

        <Stack
          spacing={2}
          alignItems="center"
          sx={{
            p: 2,
          }}
        >
          {formatRoutesToArr(routes).map(
            ({ root: { id, path, title, isNavItem, isProtected } }) =>
              isNavItem &&
              (isProtected === isWalletConnected || !isProtected) && (
                <StyledNavLink key={id} to={path} onClick={onClose}>
                  {({ isActive }) => (
                    <Typography
                      letterSpacing="0.02em"
                      sx={{
                        color: isActive ? COLOR_MINT_PRIMARY : COLOR_TEXT_WHITE,
                        fontWeight: FontWeights.Bold,
                        fontSize: '14px',
                        lineHeight: '24px',
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                        transition: TRANSITION_DEFAULT_TIME,
                        '&:hover': {
                          color: COLOR_MINT_PRIMARY_ALPHA_15,
                        },
                      }}
                    >
                      {title}
                    </Typography>
                  )}
                </StyledNavLink>
              ),
          )}
        </Stack>
      </Box>
    </Box>
  );
};
