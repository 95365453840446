import { FC } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Icon, SocialLinks } from 'components';
import { theme } from 'theme';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_LIGHT_MINT_SECONDARY, COLOR_MINT_PRIMARY } from 'theme/variables';

import { FooterDecoration } from '../../assets/img';

export const Footer: FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        py: 4.5,
        background: COLOR_LIGHT_MINT_SECONDARY,
        marginTop: 'auto',
      }}
    >
      <Stack alignItems="center" spacing={4.5}>
        <Stack direction="row" alignItems="center">
          <Icon.MainLogo
            sx={{
              color: COLOR_MINT_PRIMARY,
            }}
          />
          <Icon.MainLogoText
            sx={{
              ml: 1.5,
              color: COLOR_BLACK,
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3.75}
          width="100%"
          maxWidth="1100px"
          overflow="hidden"
        >
          <Divider sx={{ width: '100%', height: '1px', background: COLOR_MINT_PRIMARY }} />
          <SocialLinks />
          <Divider sx={{ width: '100%', height: '1px', background: COLOR_MINT_PRIMARY }} />
        </Stack>

        <Typography variant="caption" fontWeight={FontWeights.Regular} fontSize={14} sx={{ color: COLOR_BLACK }}>
          &copy; 2022 Minto. All rights reserved
        </Typography>
      </Stack>

      <FooterDecoration
        sx={{
          position: 'absolute',
          top: {
            md: '47%',
          },
          left: {
            md: -30,
          },
          [theme.breakpoints.between('xs', 1275)]: {
            top: '25%',
            left: -18,
            width: 75,
            height: 90,
          },
          transform: 'translateY(-50%)',
          color: COLOR_MINT_PRIMARY,
        }}
      />
      <FooterDecoration
        sx={{
          position: 'absolute',
          top: {
            md: '47%',
          },
          right: {
            md: -30,
          },
          [theme.breakpoints.between('xs', 1275)]: {
            top: '25%',
            right: -18,
            width: 75,
            height: 90,
          },
          transform: 'translateY(-50%)',
          color: COLOR_MINT_PRIMARY,
        }}
      />
    </Box>
  );
};
