import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BodyDecoration: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 113 279"
    sx={{
      width: 113,
      height: 279,
      fill: 'none',
      ...sx,
    }}
  >
    <path
      d="M62 92L80.9789 101.7C84.4296 103.434 88.5704 103.434 92.0211 101.7L111 92"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 78L80.9789 88.5813C84.4296 90.4729 88.5704 90.4729 92.0211 88.5813L111 78"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 65L80.9789 75.5813C84.4296 77.4729 88.5704 77.4729 92.0211 75.5813L111 65"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 52L80.9789 62.5813C84.4296 64.4729 88.5704 64.4729 92.0211 62.5813L111 52"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 48L32.0211 37.4187C28.5704 35.5271 24.4296 35.5271 20.9789 37.4187L2 48"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 61L32.0211 51.3005C28.5704 49.5665 24.4296 49.5665 20.9789 51.3005L2 61"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 75L32.0211 64.4187C28.5704 62.5271 24.4296 62.5271 20.9789 64.4187L2 75"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 88L32.0211 77.4187C28.5704 75.5271 24.4296 75.5271 20.9789 77.4187L2 88"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 157L80.9789 167.581C84.4296 169.473 88.5704 169.473 92.0211 167.581L111 157"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 144L80.9789 154.581C84.4296 156.473 88.5704 156.473 92.0211 154.581L111 144"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 131L80.9789 141.581C84.4296 143.473 88.5704 143.473 92.0211 141.581L111 131"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 118L80.9789 127.7C84.4296 129.434 88.5704 129.434 92.0211 127.7L111 118"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 28L80.9789 38.5813C84.4296 40.4729 88.5704 40.4729 92.0211 38.5813L111 28"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 15L80.9789 25.5813C84.4296 27.4729 88.5704 27.4729 92.0211 25.5813L111 15"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 2L80.9789 11.6995C84.4296 13.4335 88.5704 13.4335 92.0211 11.6995L111 1.99999"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 111L32.0211 101.3C28.5704 99.5665 24.4296 99.5665 20.9789 101.3L2 111"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 125L32.0211 114.419C28.5704 112.527 24.4296 112.527 20.9789 114.419L2 125"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 138L32.0211 127.419C28.5704 125.527 24.4296 125.527 20.9789 127.419L2 138"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 151L32.0211 141.3C28.5704 139.566 24.4296 139.566 20.9789 141.3L2 151"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 237L32.0211 226.419C28.5704 224.527 24.4296 224.527 20.9789 226.419L2 237"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 250L32.0211 240.3C28.5704 238.566 24.4296 238.566 20.9789 240.3L2 250"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 264L32.0211 253.419C28.5704 251.527 24.4296 251.527 20.9789 253.419L2 264"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51 277L32.0211 266.419C28.5704 264.527 24.4296 264.527 20.9789 266.419L2 277"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 217L80.9789 227.581C84.4296 229.473 88.5704 229.473 92.0211 227.581L111 217"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 204L80.9789 214.581C84.4296 216.473 88.5704 216.473 92.0211 214.581L111 204"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 191L80.9789 201.581C84.4296 203.473 88.5704 203.473 92.0211 201.581L111 191"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62 178L80.9789 187.7C84.4296 189.434 88.5704 189.434 92.0211 187.7L111 178"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
