import { FC } from 'react';
import { Icon, Spinner } from 'components';
import { Modals } from 'types/store/modals';

enum RepeatTransactionType {
  send,
  approve,
}

export type ModalData = {
  [key in Modals]: {
    title1?: string;
    title2?: string;
    Icon?: string | FC;
    body1?: string;
    body2?: string;
    isCopyable?: boolean;
    repeatTransactions?: keyof typeof RepeatTransactionType;
    isOkButton?: boolean;
  };
};

export const modalData: Partial<ModalData> = {
  // Transaction steps modals
  [Modals.ApprovePending]: {
    title1: 'STEP 1/2',
    title2: 'APPROVE',
    Icon: Spinner,
    body1: 'Please press "Approve" button in metamask extension',
    body2: `BEP-20 tokens are deployed with functionality that allows other smart contracts to move tokens. By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf.
The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap.`,
  },
  [Modals.ApproveRejected]: {
    title1: 'STEP 1/2',
    title2: 'APPROVE',
    Icon: Icon.Warning,
    body1: 'You rejected Approve transaction in Metamask. Press Approve again to start over or close this window.',
    body2: `BEP-20 tokens are deployed with functionality that allows other smart contracts to move tokens. By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf.
The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap.`,
    repeatTransactions: 'approve',
  },
  [Modals.SendPending]: {
    title1: 'STEP 2/2',
    title2: 'SEND',
    Icon: Spinner,
    body1: 'Please press "Send" button in Metamask extension',
    body2: 'You tokens will be transferred from your wallet to the contract address',
  },
  [Modals.SendSuccess]: {
    title1: 'STEP 2/2',
    title2: 'SEND',
    Icon: Icon.Success,
    body1: 'Sent',
    body2: 'Your transaction has been approved on the blockchain',
    isCopyable: true,
  },
  [Modals.SendRejected]: {
    title1: 'STEP 2/2',
    title2: 'SEND',
    Icon: Icon.Warning,
    body1: 'You rejected Send transaction in Metamask.',
    body2: 'Press Send again to start over or close this window.',
    repeatTransactions: 'send',
  },
  [Modals.TransferSuccess]: {
    Icon: Icon.Success,
    body1: 'Your locked BTCMT were transferred successfully!',
    isOkButton: true,
  },
};
