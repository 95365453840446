import { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, Dialog, IconButton, PaperProps, Stack, styled, Typography } from '@mui/material';
import { Close } from 'components/Icon/components';
import { COLOR_BLACK, TRANSITION_DEFAULT_TIME } from 'theme/variables';

export type ModalProps = {
  // size?: 'sm' | 'md' | 'lg';
  open: boolean;
  onClose: () => void;
  closable?: boolean;
  title?: string | ReactNode;
  sx?: PaperProps['sx'];
} & PropsWithChildren;

const CloseIconButton = styled(IconButton)({
  position: 'absolute',
  top: '18px',
  right: '19px',
});

export const Modal: FC<PropsWithChildren<ModalProps>> = ({ open, onClose, closable = true, title, children, sx }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      transitionDuration={{
        enter: 250,
        exit: 100,
      }}
      PaperProps={{ sx }}
    >
      <Box
        sx={{
          position: 'relative',
          padding: 2,
        }}
      >
        {typeof title === 'string' ? (
          <Typography variant="h5" color={COLOR_BLACK}>
            {title}
          </Typography>
        ) : (
          title
        )}
        {closable && (
          <CloseIconButton
            onClick={onClose}
            sx={{
              svg: { path: { stroke: COLOR_BLACK }, transition: TRANSITION_DEFAULT_TIME },
              '&:hover': { svg: { path: { stroke: COLOR_BLACK }, transform: 'rotate(-90deg)' } },
            }}
          >
            <Close />
          </CloseIconButton>
        )}

        <Box>{children}</Box>
      </Box>
    </Dialog>
  );
};
