import { alpha } from '@mui/material/styles';

export const COLOR_WHITE = '#FFFFFF';
export const COLOR_WHITE_ALPHA_5 = alpha(COLOR_WHITE, 0.05);
export const COLOR_GRAY = '#646464';
export const COLOR_GRAY_2 = '#A2A2A2';
export const COLOR_GRAY_3 = '#989898';
export const COLOR_GRAY_4 = '#2A3433';
export const COLOR_GRAY_5 = '#707070';
export const COLOR_BLACK = '#1A2121';
export const COLOR_DARKEST_MINT = '#1A2A2A';
export const COLOR_DARKEST_MINT_ALPHA_40 = alpha(COLOR_DARKEST_MINT, 0.4);
export const COLOR_DARK_MINT = '#137A63';
export const COLOR_DARK_MINT_ALPHA_20 = alpha(COLOR_DARK_MINT, 0.2);
export const COLOR_MINT_PRIMARY = '#6DDAC0';
export const COLOR_MINT_PRIMARY_ALPHA_10 = alpha(COLOR_MINT_PRIMARY, 0.1);
export const COLOR_MINT_PRIMARY_ALPHA_15 = alpha(COLOR_MINT_PRIMARY, 0.15);
export const COLOR_LIGHT_MINT = '#BDE2D3';
export const COLOR_LIGHT_MINT_ALPHA_15 = alpha(COLOR_LIGHT_MINT, 0.15);
export const COLOR_LIGHT_MINT_SECONDARY = '#F3F8F6';
