export interface INotifyModalProps {
  currData?: {
    [k: string]: string;
  };
  onClose: () => void;
}

export enum Modules {
  transfer = 'transfer',
}

export * from './base';
export * from './connect';
export * from './store';
export * from './contracts';
export * from './routes';
