import { DEFAULT_DECIMAL_PLACES } from 'appConstants';
import BigNumber from 'bignumber.js';

export const formatNumber = (number: number | string) => new Intl.NumberFormat().format(+number);

export const formatCurrency = (amount: number | string, locale = 'en-US', currency = 'usd'): string =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(+amount);

export const roundNumber = (
  amount: number | string,
  decimalPlaces = DEFAULT_DECIMAL_PLACES,
  roundingMode = BigNumber.ROUND_DOWN,
) => {
  return new BigNumber(new BigNumber(amount).toFixed(decimalPlaces, roundingMode)).toString(10);
};
