import { TypographyOptions } from '@mui/material/styles/createTypography';
import { COLOR_TEXT_BLACK, FontFamilies } from 'theme/variables';

export enum FontWeights {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
}

export const getTypographyOptions = (): TypographyOptions => ({
  fontFamily: FontFamilies.primary,

  allVariants: {
    color: COLOR_TEXT_BLACK,
    fontWeight: FontWeights.Medium,
    textAlign: 'left',
    fontStyle: 'normal',
  },

  h1: {
    fontSize: '64px',
    lineHeight: '76px',
    fontWeight: FontWeights.Bold,
    fontFamily: FontFamilies.primary,
  },

  h2: {
    fontSize: '40px',
    lineHeight: '48px',
    fontWeight: FontWeights.Bold,
    fontFamily: FontFamilies.primary,
  },

  h3: {
    fontSize: '38px',
    lineHeight: '48px',
    fontWeight: FontWeights.Bold,
    fontFamily: FontFamilies.primary,
  },

  h4: {
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: FontWeights.Bold,
    fontFamily: FontFamilies.primary,
  },

  h5: {
    fontSize: '24px',
    lineHeight: '34px',
    fontWeight: FontWeights.Bold,
    fontFamily: FontFamilies.primary,
  },

  body1: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: FontWeights.Medium,
    fontFamily: FontFamilies.primary,
  },

  caption: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: FontWeights.Medium,
    fontFamily: FontFamilies.primary,

    '&.s': {
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  button: {
    letterSpacing: 1,
  },
});
