import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ExternalLink: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 18"
    sx={{
      ...sx,
      width: 18,
      height: 18,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 15V9C16 8.448 16.447 8 17 8C17.553 8 18 8.448 18 9V15C18 16.654 16.654 18 15 18H3C1.346 18 0 16.654 0 15V3C0 1.346 1.346 0 3 0H9C9.553 0 10 0.448 10 1C10 1.552 9.553 2 9 2H3C2.448 2 2 2.449 2 3V15C2 15.551 2.448 16 3 16H15C15.552 16 16 15.551 16 15ZM14.5781 2.008L12.9951 2C12.4421 1.997 11.9971 1.547 12.0001 0.995C12.0031 0.444 12.4501 0 13.0001 0H13.0051L17.0021 0.02C17.5521 0.023 17.9971 0.469 17.9971 1.019L18.0001 4.999C18.0001 5.552 17.5531 6 17.0011 6H17.0001C16.4481 6 16.0001 5.553 16.0001 5.001L15.9991 3.415L9.7071 9.707C9.5121 9.902 9.2561 10 9.0001 10C8.7441 10 8.4881 9.902 8.2931 9.707C7.9021 9.316 7.9021 8.684 8.2931 8.293L14.5781 2.008Z"
      fill="currentColor"
    />
  </SvgIcon>
);
