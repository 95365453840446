import { FC } from 'react';
import { Button, Grid } from '@mui/material';
import { Icon, Modal, ModalProps } from 'components';

export type DisconnectWalletModalProps = {
  onDisconnectWallet: () => void;
} & ModalProps;

export const DisconnectWalletModal: FC<DisconnectWalletModalProps> = ({
  onDisconnectWallet,
  open,
  onClose,
  title = 'Disable your wallet?',
}) => {
  const handleDisconnect = () => {
    onDisconnectWallet();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} title={title} sx={{ maxWidth: 340 }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        rowGap={2}
        sx={{
          mt: 2.5,
        }}
      >
        <Button
          size="large"
          variant="contained"
          fullWidth
          endIcon={
            <Icon.Out
              sx={{
                ml: 1.5,
              }}
            />
          }
          onClick={handleDisconnect}
        >
          Disconnect
        </Button>
      </Grid>
    </Modal>
  );
};
