import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Twitter: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      ...sx,
      width: '16px',
      height: '16px',
    }}
  >
    <path
      d="M14.5823 4.15182C14.621 4.09797 14.5639 4.02845 14.5021 4.0524C14.063 4.22262 13.604 4.33746 13.1358 4.39406C13.6571 4.08264 14.0594 3.60859 14.2824 3.04735C14.3051 2.99017 14.2427 2.93912 14.1888 2.96886C13.7044 3.23642 13.1819 3.42908 12.6391 3.54004C12.6164 3.54469 12.593 3.53695 12.577 3.52012C12.1697 3.09034 11.6335 2.80418 11.049 2.70529C10.4523 2.60433 9.83886 2.7043 9.30503 2.98952C8.77119 3.27474 8.34712 3.72908 8.09933 4.28128C7.86372 4.80632 7.80014 5.3916 7.91635 5.95368C7.92537 5.99729 7.89123 6.03847 7.84678 6.03571C6.79074 5.97012 5.75894 5.68923 4.81494 5.20997C3.87333 4.73192 3.03951 4.06688 2.36426 3.25586C2.33429 3.21986 2.27749 3.22452 2.25566 3.26596C2.04554 3.66467 1.93553 4.10906 1.93583 4.56073C1.93498 5.01031 2.04531 5.45314 2.257 5.84977C2.46869 6.2464 2.77518 6.58453 3.14917 6.83406C2.75255 6.82327 2.36366 6.72522 2.01 6.54744C1.96442 6.52453 1.90975 6.55715 1.91212 6.60811C1.9397 7.20093 2.15733 7.79609 2.53404 8.2527C2.93492 8.73862 3.49134 9.07115 4.10917 9.19406C3.87134 9.26644 3.62442 9.3046 3.37583 9.30739C3.24054 9.30581 3.10551 9.29583 2.97152 9.27751C2.92184 9.27072 2.88128 9.31791 2.89867 9.36494C3.08295 9.86326 3.4085 10.2981 3.83655 10.6155C4.29418 10.9548 4.84622 11.1431 5.41583 11.1541C4.45397 11.9109 3.26642 12.324 2.0425 12.3274C1.91674 12.3278 1.79103 12.3237 1.66562 12.3152C1.59495 12.3104 1.56244 12.4045 1.62336 12.4407C2.81587 13.1484 4.17889 13.5224 5.56917 13.5207C6.59563 13.5314 7.61392 13.3374 8.56458 12.9501C9.51523 12.5628 10.3792 11.99 11.106 11.2651C11.8328 10.5401 12.4078 9.67765 12.7975 8.728C13.1873 7.77834 13.3839 6.76054 13.3758 5.73406V5.41417C13.3758 5.39312 13.3858 5.37333 13.4026 5.36065C13.8548 5.01922 14.2524 4.6116 14.5823 4.15182Z"
      fill="currentColor"
    />
  </SvgIcon>
);
