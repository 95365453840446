import { Chains, IContracts } from 'types';

import { transferAbi } from './abi/index';

export enum ContractsNames {
  transfer = 'transfer',
}

export const contracts: IContracts['contracts'] = {
  [ContractsNames.transfer]: {
    testnet: {
      address: {
        [Chains.Heco]: '0x1405e2445c01361d4fbdf1dcbf7a9bd07810b530',
      },
      abi: transferAbi,
    },
    mainnet: {
      address: {
        [Chains.Heco]: '0x410a56541bd912f9b60943fcb344f1e3d6f09567',
      },
      abi: transferAbi,
    },
  },
};

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts,
};
