import { FC } from 'react';
import { Box, Button, Grid, styled } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { MetamaskLogo, WalletConnectLogo } from 'modules/layout/assets';
import { FontWeights, getTypographyOptions } from 'theme/Typography';
import { COLOR_MINT_PRIMARY, COLOR_TEXT_BLACK, SHADOW_DEFAULT } from 'theme/variables';
import { Chains, WalletProviders } from 'types';

export type ConnectWalletModalProps = {
  onConnectWallet: (provider: WalletProviders, chain: Chains) => void;
} & ModalProps;

const WalletButton = styled(Button)({
  height: '68px',
  color: COLOR_TEXT_BLACK,
  ...getTypographyOptions().caption,
  fontWeight: FontWeights.ExtraBold,
  letterSpacing: '1px',
  border: `1px solid ${COLOR_MINT_PRIMARY}`,
  boxShadow: SHADOW_DEFAULT,
});

export const ConnectWalletModal: FC<ConnectWalletModalProps> = ({
  onConnectWallet,
  open,
  onClose,
  title = 'Connect wallet',
}) => {
  const handleConnect = (provider: WalletProviders, chain: Chains) => {
    onConnectWallet(provider, chain);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} title={title} sx={{ maxWidth: 340 }}>
      <Grid
        container
        rowGap={2}
        sx={{
          mt: 2.5,
        }}
      >
        <Grid item container xs={12}>
          <WalletButton
            variant="outlined"
            fullWidth
            startIcon={
              <Box
                sx={{
                  mr: 1,
                }}
              >
                <MetamaskLogo />
              </Box>
            }
            onClick={() => handleConnect(WalletProviders.metamask, Chains.Heco)}
          >
            MetaMask
          </WalletButton>
        </Grid>
        <Grid item container xs={12}>
          <WalletButton
            variant="outlined"
            fullWidth
            startIcon={
              <Box
                sx={{
                  mr: 1,
                }}
              >
                <WalletConnectLogo />
              </Box>
            }
            onClick={() => handleConnect(WalletProviders.walletConnect, Chains.Heco)}
          >
            Wallet Connect
          </WalletButton>
        </Grid>
      </Grid>
    </Modal>
  );
};
