import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services/WalletConnect';
import { setActiveModal } from 'store/modals/reducer';
import modalsSelector from 'store/modals/selectors';
import { Modals, ModalsInitialState, State } from 'types/store';

import { Modal } from '../Modals';

export const AppModal: FC = () => {
  const dispatch = useDispatch();
  const { modalState } = useShallowSelector<State, ModalsInitialState>(modalsSelector.getModals);
  const { connect, disconnect } = useWalletConnectorContext();

  const handleCloseModal = useCallback(() => {
    dispatch(
      setActiveModal({
        activeModal: Modals.init,
        txHash: '',
        open: false,
      }),
    );
  }, [dispatch]);

  if (modalState.activeModal === Modals.init) return null;

  return (
    <>
      {modalState.activeModal === Modals.ConnectWallet && (
        <Modal.ConnectWalletModal open={modalState.open} onConnectWallet={connect} onClose={handleCloseModal} />
      )}
      {modalState.activeModal === Modals.DisconnectWallet && (
        <Modal.DisconnectWalletModal
          open={modalState.open}
          onDisconnectWallet={disconnect}
          onClose={handleCloseModal}
        />
      )}
      {[
        Modals.ApprovePending,
        Modals.ApproveRejected,
        Modals.SendPending,
        Modals.SendRejected,
        Modals.SendSuccess,
        Modals.TransferSuccess,
      ].includes(modalState.activeModal) && <Modal.TransactionModal />}
    </>
  );
};
