import { Dialog, Typography } from '@mui/material';
import { ComponentMeta, ComponentStory } from '@storybook/react';

export default {
  title: 'theme/Dialog',
  component: Dialog,
} as ComponentMeta<typeof Dialog>;

const Template: ComponentStory<typeof Dialog> = (args) => (
  <Dialog {...args}>
    <Typography>MODAL</Typography>
  </Dialog>
);
export const Default = Template.bind({});

Default.args = {
  open: true,
  title: '123',
};
