import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Discord: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 12"
    sx={{
      ...sx,
      width: '16px',
      height: '16px',
    }}
  >
    <path
      d="M13.0079 0.964844C12.0704 0.511719 11.0743 0.183594 10.0313 0C9.90243 0.238281 9.75399 0.558594 9.65243 0.8125C8.54696 0.644531 7.44931 0.644531 6.35556 0.8125C6.25399 0.558594 6.10165 0.238281 5.97665 0C4.92977 0.183594 3.93368 0.511719 3.00009 0.964844C1.11727 3.89062 0.605555 6.75 0.859461 9.56641C2.11337 10.5234 3.3204 11.1055 4.5079 11.4883C4.79696 11.0781 5.06259 10.6328 5.28524 10.1641C4.85555 9.99609 4.4493 9.78906 4.05477 9.54297C4.16024 9.46875 4.2618 9.38281 4.35555 9.30078C6.72665 10.4414 9.29696 10.4414 11.6407 9.30078C11.7423 9.38281 11.836 9.46875 11.9376 9.54297C11.547 9.78906 11.1368 9.99609 10.7071 10.1641C10.9337 10.6328 11.1954 11.0781 11.4884 11.4883C12.672 11.1055 13.8868 10.5234 15.1329 9.56641C15.4454 6.30469 14.6368 3.46875 13.0079 0.964844ZM5.61337 7.82812C4.90243 7.82812 4.3204 7.14844 4.3204 6.3125C4.3204 5.47656 4.8868 4.79688 5.61337 4.79688C6.33602 4.79688 6.92196 5.47656 6.91024 6.3125C6.91024 7.14844 6.33602 7.82812 5.61337 7.82812ZM10.3946 7.82812C9.68368 7.82812 9.09774 7.14844 9.09774 6.3125C9.09774 5.47656 9.66806 4.79688 10.3946 4.79688C11.1173 4.79688 11.7071 5.47656 11.6915 6.3125C11.6915 7.14844 11.1251 7.82812 10.3946 7.82812Z"
      fill="currentColor"
    />
  </SvgIcon>
);
