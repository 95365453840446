import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Telegram: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      ...sx,
      width: '16px',
      height: '16px',
    }}
  >
    <path
      d="M12.3646 13.1931L12.3651 13.192L12.3767 13.163L14.3759 3.08362V3.05089C14.3759 2.79975 14.2831 2.58051 14.0811 2.44901C13.9044 2.3339 13.7012 2.3257 13.5588 2.33651C13.4081 2.34795 13.266 2.38556 13.1676 2.41687C13.117 2.43297 13.0747 2.44849 13.0444 2.46023C13.0292 2.46612 13.0169 2.47112 13.0079 2.47484L12.9991 2.47855L1.85257 6.85117L1.84951 6.85227C1.84349 6.85446 1.83552 6.85743 1.82591 6.86118C1.80674 6.86864 1.78065 6.87932 1.74999 6.89321C1.68981 6.92049 1.60665 6.96258 1.52231 7.02033C1.37908 7.11842 1.10634 7.35064 1.15239 7.71879C1.19054 8.02373 1.40074 8.21739 1.54245 8.31766C1.61842 8.37141 1.69109 8.41017 1.74431 8.43551C1.77128 8.44835 1.79418 8.45818 1.81124 8.46513C1.81979 8.46861 1.82694 8.47139 1.83248 8.47349L1.83956 8.47614L1.84409 8.47782L3.79425 9.13435C3.78767 9.25671 3.7998 9.38147 3.83225 9.50455L4.80911 13.2102C4.92493 13.6495 5.32241 13.9556 5.77679 13.9553C6.18408 13.955 6.5453 13.7086 6.69876 13.341L8.22388 11.7102L10.8432 13.7184L10.8804 13.7346C11.1184 13.8385 11.3408 13.8714 11.544 13.8436C11.747 13.8159 11.9084 13.7308 12.0295 13.6338C12.1488 13.5384 12.2305 13.4308 12.2819 13.3497C12.308 13.3085 12.3275 13.2723 12.341 13.2451C12.3477 13.2314 12.3531 13.2198 12.3571 13.2108L12.3621 13.1992L12.3639 13.1949L12.3646 13.1931ZM4.79918 9.24964C4.77698 9.16542 4.81184 9.07652 4.88536 9.02983L11.5 4.82922C11.5 4.82922 11.8891 4.59302 11.8752 4.82922C11.8752 4.82922 11.9446 4.87093 11.7361 5.06542C11.5384 5.25013 7.02169 9.61084 6.56467 10.0521C6.53829 10.0776 6.52254 10.1068 6.51324 10.1423L5.77604 12.9552L4.79918 9.24964Z"
      fill="currentColor"
    />
  </SvgIcon>
);
