import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Warning: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 100 100"
    sx={{
      width: '100px',
      height: '100px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.5 100C78.1142 100 100.5 77.6142 100.5 50C100.5 22.3858 78.1142 0 50.5 0C22.8858 0 0.5 22.3858 0.5 50C0.5 77.6142 22.8858 100 50.5 100ZM43.2425 22.1588H57.7556L56.4283 63.1589H44.5698L43.2425 22.1588ZM58.0153 74.5847C57.9576 78.7395 54.4663 82.0865 50.5135 82.0865C46.3587 82.0865 42.954 78.7395 43.0117 74.5847C42.954 70.4876 46.3587 67.1695 50.5135 67.1695C54.4663 67.1695 57.9576 70.4876 58.0153 74.5847Z"
      fill="currentColor"
    />
  </SvgIcon>
);
