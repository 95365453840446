import { FC } from 'react';
import { IconButton, Link, Stack } from '@mui/material';
import { COLOR_BLACK, COLOR_DARK_MINT, COLOR_WHITE, TRANSITION_DEFAULT_TIME } from 'theme/variables';

import { socialsItems } from './SocialLinks.helpers';

export const SocialLinks: FC = () => {
  return (
    <Stack spacing={3} direction="row">
      {socialsItems.map(({ id, Icon, link }) => (
        <Link key={id} href={link} target="_blank" rel="noreferrer">
          <IconButton
            sx={{
              padding: 1.25,
              background: COLOR_BLACK,
              transition: TRANSITION_DEFAULT_TIME,
              '&:hover': {
                background: COLOR_DARK_MINT,
              },
            }}
          >
            <Icon sx={{ color: COLOR_WHITE }} width="16px" height="16px" />
          </IconButton>
        </Link>
      ))}
    </Stack>
  );
};
