import { ComponentsOverrides, ComponentsProps, outlinedInputClasses, Theme } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import {
  BG_MAIN,
  BORDER_COLOR_INPUT_DEFAULT,
  BORDER_COLOR_INPUT_FOCUS,
  BORDER_COLOR_INPUT_HOVER,
  BORDER_RADIUS_DEFAULT,
  COLOR_DARKEST_MINT,
  COLOR_GRAY,
  COLOR_MINT_PRIMARY,
  COLOR_TEXT_GRAY_DARK,
  COLOR_TEXT_WHITE,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';

export const getMuiInputBaseOverrides = (): ComponentsOverrides['MuiInputBase'] => ({
  input: {
    '&:-webkit-autofill': {
      boxShadow: '0 0 0 1000px transparent inset !important',
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiTextFieldOverrides = (theme?: Theme): ComponentsOverrides['MuiTextField'] => ({
  root: {
    boxShadow: 'none',
    border: '1px solid',
    borderColor: 'transparent',
    borderRadius: BORDER_RADIUS_DEFAULT,
    fontSize: '18px',
    lineHeight: '28px',

    '& input::placeholder': {
      opacity: 1,
      color: COLOR_TEXT_GRAY_DARK,
      fontWeight: FontWeights.Medium,
    },
    [`& .${outlinedInputClasses.input}`]: {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
    },
    [`& .${outlinedInputClasses.root}`]: {
      color: COLOR_MINT_PRIMARY,
      borderRadius: BORDER_RADIUS_DEFAULT,
      background: COLOR_DARKEST_MINT,
      transition: TRANSITION_DEFAULT_TIME,

      '&:hover': {
        color: COLOR_TEXT_WHITE,
      },

      '& fieldset': {
        transition: TRANSITION_DEFAULT_TIME,

        border: '1px solid',
        borderColor: BORDER_COLOR_INPUT_DEFAULT,
      },
      '&:hover fieldset': {
        borderColor: BORDER_COLOR_INPUT_HOVER,
      },
      '&.Mui-focused fieldset': {
        borderColor: BORDER_COLOR_INPUT_FOCUS,
      },
      '&.Mui-error fieldset': {
        borderColor: '#d32f2f',
      },

      '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: COLOR_GRAY,
      },
      '&.Mui-disabled fieldset': {
        borderColor: COLOR_GRAY,
      },
      '&.Mui-disabled': {
        backgroundColor: BG_MAIN,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgba(26, 26, 26, 0.2)',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'rgba(26, 26, 26, 0.2)',
    },
  },
});

export const getMuiTextFieldDefaultProps = (theme: Theme): ComponentsProps['MuiTextField'] => ({
  inputProps: {
    style: {
      height: 44,
      padding: theme.spacing(1, 3),
    },
  },
});
