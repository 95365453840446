import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Out: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 15 20"
    sx={{
      ...sx,
      width: '15px',
      height: '20px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.48733 2H2V17.8402H7.48733C8.03961 17.8402 8.48733 18.2879 8.48733 18.8402C8.48733 19.3924 8.03961 19.8402 7.48733 19.8402H1.81092C1.33063 19.8402 0.870019 19.6494 0.530405 19.3098C0.190792 18.9701 0 18.5095 0 18.0292V1.81092C0 1.33063 0.190793 0.870017 0.530405 0.530405C0.870017 0.190793 1.33063 0 1.81092 0H7.48733C8.03961 0 8.48733 0.447715 8.48733 1C8.48733 1.55228 8.03961 2 7.48733 2ZM9.03557 4.95567C9.42609 4.56515 10.0593 4.56515 10.4498 4.95567L14.7071 9.21298C15.0976 9.60351 15.0976 10.2367 14.7071 10.6272L10.4498 14.8845C10.0593 15.275 9.42609 15.275 9.03557 14.8845C8.64504 14.494 8.64504 13.8608 9.03557 13.4703L11.5858 10.9201L5.48535 10.9201C4.93307 10.9201 4.48535 10.4724 4.48535 9.9201C4.48535 9.36782 4.93307 8.9201 5.48535 8.9201L11.5858 8.92011L9.03557 6.36989C8.64504 5.97936 8.64504 5.3462 9.03557 4.95567Z"
      fill="currentColor"
    />
  </SvgIcon>
);
