import { buttonClasses, ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import { FontFamilies, TRANSITION_DEFAULT_TIME } from 'theme/variables';
import {
  BG_BUTTON_PRIMARY,
  BG_BUTTON_PRIMARY_DISABLED,
  BG_BUTTON_PRIMARY_HOVER,
  BG_BUTTON_SECONDARY,
  BG_BUTTON_SECONDARY_DISABLED,
  BG_BUTTON_SECONDARY_HOVER,
  BORDER_BUTTON_PRIMARY_DISABLED,
  BORDER_BUTTON_SECONDARY,
  BORDER_BUTTON_SECONDARY_DISABLED,
  BORDER_BUTTON_SECONDARY_HOVER,
  BORDER_RADIUS_BUTTON_DEFAULT,
  COLOR_BUTTON_PRIMARY,
  COLOR_BUTTON_PRIMARY_DISABLED,
  COLOR_BUTTON_PRIMARY_HOVER,
  COLOR_BUTTON_SECONDARY,
  COLOR_BUTTON_SECONDARY_DISABLED,
  COLOR_BUTTON_SECONDARY_HOVER,
} from 'theme/variables/button';

export const getMuiButtonOverrides = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: {
    textAlign: 'center',
    borderRadius: BORDER_RADIUS_BUTTON_DEFAULT,
    transition: TRANSITION_DEFAULT_TIME,
    textTransform: 'uppercase',
    fontWeight: FontWeights.ExtraBold,
    padding: theme.spacing(2, 2),
    fontSize: '18px',
    lineHeight: '28px',
    fontFamily: FontFamilies.primary,
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
    },
  },

  startIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    marginRight: '4px',
  },

  endIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    marginLeft: '4px',
  },

  sizeSmall: {
    minWidth: '70px',
    fontSize: '12px',
    lineHeight: '16px',
  },

  sizeMedium: {
    padding: theme.spacing(0, 2),
    height: '44px',
  },
  sizeLarge: {
    padding: theme.spacing(0, 2),
    height: '56px',
  },

  outlined: {
    color: COLOR_BUTTON_SECONDARY,
    border: BORDER_BUTTON_SECONDARY,
    backgroundColor: BG_BUTTON_SECONDARY,

    '&:hover': {
      color: COLOR_BUTTON_SECONDARY_HOVER,
      border: BORDER_BUTTON_SECONDARY_HOVER,
      backgroundColor: BG_BUTTON_SECONDARY_HOVER,
    },

    '&:active': {
      color: COLOR_BUTTON_SECONDARY_HOVER,
      backgroundColor: BG_BUTTON_SECONDARY_HOVER,
    },

    [`&.${buttonClasses.disabled}`]: {
      color: COLOR_BUTTON_SECONDARY_DISABLED,
      border: BORDER_BUTTON_SECONDARY_DISABLED,
      backgroundColor: BG_BUTTON_SECONDARY_DISABLED,
    },
  },

  contained: {
    color: COLOR_BUTTON_PRIMARY,
    backgroundColor: BG_BUTTON_PRIMARY,

    '&:hover': {
      color: COLOR_BUTTON_PRIMARY_HOVER,
      backgroundColor: BG_BUTTON_PRIMARY_HOVER,
    },

    '&:active': {
      color: COLOR_BUTTON_PRIMARY_HOVER,
      backgroundColor: BG_BUTTON_PRIMARY_HOVER,
    },

    [`&.${buttonClasses.disabled}`]: {
      color: COLOR_BUTTON_PRIMARY_DISABLED,
      border: BORDER_BUTTON_PRIMARY_DISABLED,
      backgroundColor: BG_BUTTON_PRIMARY_DISABLED,
    },
  },

  containedSecondary: {
    color: COLOR_BUTTON_PRIMARY,
    backgroundColor: BG_BUTTON_PRIMARY,

    '&:hover': {
      backgroundColor: BG_BUTTON_PRIMARY_HOVER,
    },

    '&:active': {
      backgroundColor: BG_BUTTON_PRIMARY_HOVER,
    },

    [`&.${buttonClasses.disabled}`]: {
      color: COLOR_BUTTON_PRIMARY_DISABLED,
      border: BORDER_BUTTON_PRIMARY_DISABLED,
      backgroundColor: BG_BUTTON_PRIMARY_DISABLED,
    },
  },

  text: {
    background: 'none',
    backgroundColor: 'transparent',
    transition: '0',
    boxSizing: 'border-box',
    borderColor: 'transparent',
    borderRadius: 0,
    padding: 0,
    margin: 0,
    minWidth: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&.secondary': {
      ...theme.typography.subtitle1,
      fontFamily: FontFamilies.primary,
      padding: 0,
      textTransform: 'none',
      color: COLOR_BUTTON_SECONDARY,
      height: 'unset',
    },
  },
});

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'contained',
});
